
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import BasicPus from "@/views/manage/documents/steps/BasicPus.vue";
import BusinessPus from "@/views/manage/documents/steps/BusinessPus.vue";
import SapsCompentency from "@/views/manage/documents/steps/SapsCompentency.vue";
import Regulation21 from "@/views/manage/documents/steps/Regulation21.vue";
import TacticalUnitStandards from "@/views/manage/documents/steps/TacticalUnitStandards.vue";
import { Certificate } from "models";
import Docs from "@/store/modules/Documents";
import dayjs from "dayjs";
import { axiosInstance } from "@/plugins/axios";
import { CRUDFunctionality, Documents } from "@/hooks";
import logger from "@/plugins/logger";

@Component({
  components: {
    BusinessPus,
    BasicPus,
    SapsCompentency,
    Regulation21,
    TacticalUnitStandards,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = today.diff(exp, "d");

      if (rem < 0) {
        return -1 * rem + " days remaining";
      } else {
        return rem + " days late";
      }
    },
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
    gunAbbr: function (value: string) {
      switch (value) {
        case "Manually-Operated Rifle":
          return "MOR";
        case "Self-Loading Rifle":
          return "SLR";
        case "Shotgun":
          return "SG";
        case "Handgun":
          return "HG";
        default:
          return value;
      }
    },
    gunIcons: function (value: string) {
      switch (value) {
        case "Manually-Operated Rifle":
          return "$manualRifle";
        case "Self-Loading Rifle":
          return "$selfLoadingRifle";
        case "Shotgun":
          return "$shotgun";
        case "Handgun":
          return "$handgun";
      }
      return JSON.parse(JSON.stringify(value, null, 4));
    },
  },
})
export default class ComplexDataIterator extends Vue {
  @Prop() comp!: BusinessPus | SapsCompentency | null;
  @Prop() items!: any[];
  addItem = false;
  panel: number | undefined = 0;
  deleteModal = false;
  deleteParam: string | null = null;

  mounted(): void {
    this.items.forEach((item, index) => {
      if (index == 0) {
        this.triggerEdit(item, true);
      }
    });
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  goto(refName: string) {
    setTimeout(() => {
      var element = this.$refs[refName] as any;
      if (element) {
        // logger.warn("Offset ?? :", element.$el.getBoundingClientRect().y);
        var top = element.$el.offsetTop + 400;
        // logger.log("----- GOTO :", refName, top, element, this.$refs);

        window.scrollTo(0, top);
      }
    }, 200);
  }

  checkDaysRemaining(value: string) {
    const today = dayjs();
    const exp = dayjs(value);
    const rem = today.diff(exp, "d");
    return rem;
  }

  async downloadwPath(i: any): Promise<void> {
    try {
      await Documents.getFile(i).then((response: any) => {
        const url = window.URL.createObjectURL(response);
        window.open(url);
        logger.log(response);
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  get getItems() {
    return this.items;
  }

  edit: {
    view: boolean;
    item: Certificate | null;
  } = {
    view: false,
    item: null,
  };
  get getAroDetails() {
    return Docs.getAroDocs;
  }

  triggerEdit(item: any | null, val: boolean): void {
    if (item != null && item == this.edit.item) {
      Vue.set(this.edit, "view", val);
    } else if (item != this.edit.item) {
      Vue.set(this.edit, "view", !val);
      setTimeout(() => {
        Vue.set(this.edit, "item", item);
        Vue.set(this.edit, "view", val);
      }, 200);
    } else {
      Vue.set(this.edit, "view", val);
    }

    // if (this.edit.view == true && this.edit.item != null)
    //   Vue.set(this.edit, "view", !this.edit.view);
    // setTimeout(() => {
    //   Vue.set(this.edit, "item", item);
    //   Vue.set(this.edit, "view", !this.edit.view);
    // }, 200);

    // if (item != null) {
    //   if (this.edit.item == item) {
    //     if (this.edit.view == true) {
    //       Vue.set(this.edit, "view", false);
    //       setTimeout(() => Vue.set(this.edit, "item", null), 200);
    //     } else {
    //       Vue.set(this.edit, "item", item);
    //       Vue.set(this.edit, "view", true);
    //     }
    //   } else {
    //     logger.log("Diff item to edit");
    //     if (this.edit.view == true && this.edit.item != null)
    //       Vue.set(this.edit, "view", !this.edit.view);
    //     setTimeout(() => {
    //       Vue.set(this.edit, "item", item);
    //       Vue.set(this.edit, "view", !this.edit.view);
    //     }, 200);
    //   }
    // } else {
    //   if (this.edit.view == true && this.edit.item != null)
    //     Vue.set(this.edit, "view", !this.edit.view);
    //   setTimeout(() => {
    //     Vue.set(this.edit, "item", null);
    //     Vue.set(this.edit, "view", !this.edit.view);
    //   }, 200);
    // }
  }
  deleteDoc(comp: string, id: number) {
    logger.log("Ah haaaa : ", comp, id);
    if (id) {
      let param = "/api/ManageDocumentsAPI";
      switch (comp) {
        case "BasicPus":
          param += "/deleteBasicProficiency?id=";
          break;
        case "SapsCompentency":
          param += "/deleteSAPSCompetency?option=4&id=";
          break;
        case "Regulation21":
          param += "/deleteSAPSCompetency?option=4&id=";
          break;
        case "BusinessPus":
          param += "/deleteBusinessProficiency?id=";
          break;
      }
      param += id;
      this.deleteParam = param;
      this.deleteModal = true;
    }
  }

  async processDelete() {
    try {
      if (this.deleteParam) {
        const res = await axiosInstance.delete(this.deleteParam);
        this.deleteParam = null;
        this.refresh();
      } else {
        this.$notifCreator.createErrorNotification(
          "Something went wrong while deleting the file. If this problem persists, please contact the system administrator."
        );
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  refresh() {
    this.$emit("refresh");
  }

  @Watch("panel", { immediate: true })
  panelCHanged() {
    logger.log("panel", this.panel);
  }
}
