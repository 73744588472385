
import { Component, Vue } from "vue-property-decorator";
import { IndexedDB } from "@/hooks";
import { OfflinePermitType } from "models";
import AuthModule from "@/store/modules/Auth";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import logger from "@/plugins/logger";
import MultimediaViewer from "@/components/MultimediaViewer.vue";

@Component({
  components: {
    VuePdfApp,
    MultimediaViewer,
  },
})
export default class OfflinePermits extends Vue {
  viewPermit: OfflinePermitType | null = null;
  openViewPermit = false;
  permits: OfflinePermitType[] = [];
  async mounted(): Promise<void> {
    logger.log("Check offline permits :", AuthModule.getUsername);
    if (AuthModule.getUsername != null)
      this.permits = await IndexedDB.getStoredPermits(AuthModule.getUsername);
    return Promise.resolve();
  }
  async deletePermit(item: OfflinePermitType): Promise<void> {
    if (AuthModule.getUsername != null) {
      await IndexedDB.deletePermit(item.id, AuthModule.getUsername);
      this.permits = await IndexedDB.getStoredPermits(AuthModule.getUsername);
    }
    return Promise.resolve();
  }
  setViewPermit(item: OfflinePermitType): void {
    logger.log("View Permit", item);
    this.viewPermit = item;
    this.openViewPermit = true;
  }
}
