
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import {
  Ammunition,
  ArmResponseOfficer,
  AROViewModel,
  Firearm,
  PermitCreate,
  Shift,
  ShiftSite as ShiftSiteModel,
} from "models";
import { Certification } from "@/models/documents";
import {
  Ammo,
  AROs,
  Permits,
  ShiftSite,
  Firearms,
  Shifts,
  Downloader,
} from "@/hooks";
//import { btoB } from "@/utils/files";
import dayjs from "dayjs";
import Auth from "@/store/modules/Auth";
import Signature from "@/components/Signature.vue";
import logger from "@/plugins/logger";
import axios, { AxiosError } from "axios";

@Component({
  components: {
    VuePdfApp,
    Signature,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class IssuePermits extends Vue {
  @PropSync("open", { type: Boolean }) openModal!: boolean;
  // @Prop() readonly loading!: boolean;
  loading = false;
  valid = false;
  addAroSignature = false;
  addPersonSignature = false;
  issueSignature: string | null = null;
  specialCond1 = false;
  specialCond2 = false;
  prepopulate = false;
  permitCheckedBy = false;
  // Armed Response Officers
  aro: AROViewModel | null = null;
  aroFilter = "";
  shiftsFilter = "";
  shiftDisabled = false;
  shiftSiteDisabled = false;
  clearSignature = false;
  aros: AROViewModel[] = [];
  async loadAros(): Promise<void> {
    const res = await AROs.getAros();
    this.aros = res.arOs;
  }
  get getAros(): AROViewModel[] {
    return this.aros.filter(
      (e) =>
        e.name.toLowerCase().includes(this.aroFilter.toLowerCase()) ||
        e.surname.toLowerCase().includes(this.aroFilter.toLowerCase())
    );
  }

  get getShifts(): Shift[] {
    return this.shifts.filter(
      (e) =>
        e.startTime.toLowerCase().includes(this.shiftsFilter.toLowerCase()) ||
        e.startDate.toLowerCase().includes(this.shiftsFilter.toLowerCase()) ||
        e.endDate.toLowerCase().includes(this.shiftsFilter.toLowerCase()) ||
        e.endTime.toLowerCase().includes(this.shiftsFilter.toLowerCase()) ||
        e.team.name.toLowerCase().includes(this.shiftsFilter.toLowerCase()) ||
        e.shiftSite.name.toLowerCase().includes(this.shiftsFilter.toLowerCase())
    );
  }
  @Watch("aro", { immediate: true })
  async selectAro(
    newVal: Firearm | null,
    oldVal: Firearm | null | undefined
  ): Promise<void> {
    try {
      this.loading = true;
      if (this.prepopulate == true) {
        if (this.aro && this.aro.id) {
          await this.prepopulateFirearm(this.aro.id);
          await this.callAmmoAvail();
        }
      }
      if (newVal != null && this.firearm != null) {
        let val = await Firearms.validateFirearm(this.firearm.id, newVal.id);
        this.validFirearm = val.status == 200;
        this.firearmErrorMessage = null;
        this.validCompetency = val.sapsCompetencyCertificate || null;
        // this.$notifCreator.createSuccessNotification("Valid Firearm");
        // await this.prepopulate(newVal.id, this.firearm.id);
      }

      if (this.aro && this.aro.id) {
        logger.warn("getting shifts");
        await this.loadShifts();
      }
    } catch (err) {
      this.validFirearm = false;
      if (axios.isAxiosError(err)) {
        this.firearmErrorMessage = (err as any).response.data.message;
      } else {
        this.firearmErrorMessage = (err as any).message;
      }
    } finally {
      this.loading = false;
    }
  }
  /**
   * Firearms
   *
   * The following loads the list of firearms manages their filtering and subsequent selection.
   */
  firearm: Firearm | null = null;
  firearms: Firearm[] = [];
  async loadFirearms(): Promise<void> {
    this.firearms = await Firearms.getAllFirearms(false);
  }
  /**
   * Firearms Filter
   *
   * The following filters the list of firearms
   */
  firearmFilter = "";
  get getFirearms(): Firearm[] {
    return this.firearms.filter(
      (e) =>
        e.make.toLowerCase().includes(this.firearmFilter.toLowerCase()) ||
        e.model.toLowerCase().includes(this.firearmFilter.toLowerCase()) ||
        e.serialNumber
          .toLowerCase()
          .includes(this.firearmFilter.toLowerCase()) ||
        e.typeStr.toLowerCase().includes(this.firearmFilter.toLowerCase()) ||
        e.fromDateStr.toLowerCase().includes(this.firearmFilter.toLowerCase())
    );
  }
  /**
   * Firearm
   *
   * The following validates the selected firearm
   */
  validFirearm = false;
  validCompetency: Certification | null = null;
  permit: Blob | null = null;
  pdfConfig = {
    toolbar: false,
  };
  firearmErrorMessage: null | string = null;
  firearmAvailError: null | string = null;
  @Watch("firearm", { immediate: true })
  async selectFirearm(
    newVal: Firearm | null,
    oldVal: Firearm | null | undefined
  ): Promise<void> {
    this.loading = true;
    try {
      logger.log("Prepopulate");
      if (this.prepopulate == true) {
        if (this.aro && this.aro.id && this.firearm && this.firearm.id) {
          await this.prepopulatePermitDetails(this.aro.id, this.firearm.id);
          await this.callAmmoAvail();
          if (this.shift) {
            this.shiftSiteDisabled = true;
          } else {
            this.shiftSiteDisabled = false;
          }

          if (this.shiftSite) {
            this.shiftDisabled = true;
          } else {
            this.shiftDisabled = false;
          }
        }
      }
      if (newVal != null && this.aro != null) {
        const val = await Firearms.validateFirearm(newVal.id, this.aro.id!);
        this.validFirearm = true;
        this.firearmErrorMessage = null;
        // this.$notifCreator.createSuccessNotification("Valid Firearm");
        // await this.prepopulate(this.aro.id!, newVal.id);
        // logger.log(val);
        this.validCompetency = new Certification(val.sapsCompetencyCertificate);
        // let blob = new Blob([val.blob], { type: "image/jpg" });
        // this.validCompetency = window.URL.createObjectURL(blob);
        // logger.log(this.validCompetency);
        // logger.log(this.validCompetency.pdf());
      } else if (newVal == null) {
        this.firearm = newVal;
        this.validFirearm = false;
      }
      // Check if the firearm is available
      if (this.aro && this.firearm) {
        try {
          const res = await Firearms.checkFirearmAvailability(
            this.firearm.id,
            this.aro.securityFirmID,
            this.fromDate,
            this.fromTime,
            this.toDate,
            this.toTime,
            this.aro.id!
          );
          this.firearmAvailability = res.status == "Success" ? true : false;

          if (this.firearmAvailability == false) {
            this.firearmAvailError = res.message;
          }
        } catch (err) {
          logger.error("Not valid firearm ", err);
          this.firearmAvailability = false;
          // this.firearmAvailError = err;
          // throw err;
        }
      }
      // Clear Ammunition
      this.ammunitionRequested = null;
      this.validate();
    } catch (err) {
      logger.log("Competency Certiicate: Fail");
      this.firearm = null;
      this.validFirearm = false;
      if (axios.isAxiosError(err)) {
        this.firearmErrorMessage = (err as any).response.data.message;
      } else {
        this.firearmErrorMessage = (err as any).message;
      }
      return Promise.reject(err);
      // this.$notifCreator.createErrorNotification(`Invalid Firearm: ${err.message}`);
    } finally {
      logger.log("Stop Loading");
      this.loading = false;
    }
  }
  get getFirearmError(): string | null {
    return this.firearmErrorMessage;
  }
  // rules = {
  //   ammoMax: (v: string): boolean | string =>
  //     (parseInt(v) < this.getAmmoAvail && parseInt(v) > 0) ||
  //     `Not enough ammunition available ${v}  ${this.getAmmoAvail} ${
  //       parseInt(v) < this.getAmmoAvail
  //     }`,
  // };
  // get getRules(): ((v: string) => boolean | string) {
  //   return this.rules;
  // }

  /**
   * Prepopulate
   */

  handleSignatureUpdate(b64: string) {
    logger.log("Signature: ", b64);
    if (b64 != undefined) {
      this.issueSignature = b64;
    }
  }
  async prepopulateFirearm(AROID: number): Promise<void> {
    try {
      const defaults = await Permits.getAutoPopulateFirearm(AROID);
      logger.log("Defaults Firearm", defaults);
      Promise.resolve();

      if (defaults) this.firearm = defaults[defaults.length - 1].firearm;
    } catch (err) {
      Promise.reject(err);
    }
  }
  async prepopulatePermitDetails(
    AROID: number,
    firearmId: number
  ): Promise<void> {
    try {
      const defaults = await Permits.getAssignFirearmFields(AROID, firearmId);
      logger.log("Defaults Permit Details", defaults);
      Promise.resolve();

      if (defaults) {
        this.ammunitionRequested =
          defaults[defaults.length - 1].ammunitionCount;
        this.shiftSite = defaults[defaults.length - 1].shiftSite;
        this.numberOfMags = defaults[defaults.length - 1].numberOfMagazines;
        this.natureOfDuty = defaults[defaults.length - 1].natureOfDuty;
        logger.log("shiftSite", this.shiftSite);
      }
    } catch (err) {
      Promise.reject(err);
    }
  }
  /**
   * Firearm Availability
   */
  fromDate = dayjs().format("YYYY-MM-DD");
  fromDatePicker = false;
  fromTime = dayjs().format("HH:mm");
  fromTimePicker = false;
  toDate = dayjs().format("YYYY-MM-DD");
  toDatePicker = false;
  toTime = dayjs().add(1, "h").format("HH:mm");
  toTimePicker = false;
  minToDate = this.fromDate;
  minToTime = this.fromTime;
  firearmAvailability: boolean | null = null;
  @Watch("fromDate", { immediate: true })
  async fromDateChanged(v: string): Promise<void> {
    try {
      logger.log("From Date Changed");
      this.minToDate = this.fromDate;
      if (dayjs(this.toDate).isBefore(this.fromDate)) {
        this.toDate = this.fromDate;
      }
      if (this.fromDate == this.toDate) {
        this.minToTime = this.fromTime;
        if (this.fromTime > this.toTime) {
          this.toTime = this.fromTime;
        }
      } else {
        this.minToTime = "";
      }
      if (this.aro && this.firearm) {
        const res = await Firearms.checkFirearmAvailability(
          this.firearm.id,
          this.aro.securityFirmID,
          this.fromDate,
          this.fromTime,
          this.toDate,
          this.toTime,
          this.aro.id!
        ).catch((err: any) => {
          logger.log(err);
          this.firearmAvailability = false;
          return Promise.reject(err);
        });
        this.firearmAvailability = res.status == "Success" ? true : false;

        if (this.firearmAvailability == false) {
          this.firearmAvailError = res.message;
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @Watch("fromTime", { immediate: true })
  async fromTimeChanged(): Promise<void> {
    logger.log("From Time Changed");
    if (this.fromDate == this.toDate) {
      this.minToTime = this.fromTime;
      if (this.fromTime > this.toTime) {
        this.toTime = this.fromTime;
      }
    } else {
      this.minToTime = "";
    }
    if (this.aro && this.firearm) {
      const res = await Firearms.checkFirearmAvailability(
        this.firearm.id,
        this.aro.securityFirmID,
        this.fromDate,
        this.fromTime,
        this.toDate,
        this.toTime,
        this.aro.id!
      ).catch((err: any) => {
        logger.log(err);
        this.firearmAvailability = false;
        // this.firearmAvailError = err;
        return Promise.reject(err);
      });
      this.firearmAvailability = res.status == "Success" ? true : false;

      if (this.firearmAvailability == false) {
        this.firearmAvailError = res.message;
      }
    }
  }

  @Watch("toDate", { immediate: true })
  async toDateChanged(): Promise<void> {
    logger.log("To Date Changed");
    if (this.fromDate == this.toDate) {
      if (this.toTime < this.fromTime) {
        this.minToTime = this.fromTime;
        this.toTime = this.fromTime;
      }
    } else {
      this.minToTime = "";
    }
    if (this.aro && this.firearm) {
      const res = await Firearms.checkFirearmAvailability(
        this.firearm.id,
        this.aro.securityFirmID,
        this.fromDate,
        this.fromTime,
        this.toDate,
        this.toTime,
        this.aro.id!
      ).catch((err: any) => {
        logger.log(err);
        this.firearmAvailability = false;
        return Promise.reject(err);
      });
      this.firearmAvailability = res.status == "Success" ? true : false;

      if (this.firearmAvailability == false) {
        this.firearmAvailError = res.message;
      }
    }
  }

  @Watch("toTime", { immediate: true })
  async toTimeChanged(): Promise<void> {
    logger.log("To Time Changed");
    if (this.fromDate == this.toDate) {
      this.minToTime = this.fromTime;
    } else {
      this.minToTime = "";
    }
    if (this.aro && this.firearm) {
      const res = await Firearms.checkFirearmAvailability(
        this.firearm.id,
        this.aro.securityFirmID,
        this.fromDate,
        this.fromTime,
        this.toDate,
        this.toTime,
        this.aro.id!
      ).catch((err: any) => {
        logger.log(err);
        this.firearmAvailability = false;
        return Promise.reject(err);
      });
      this.firearmAvailability = res.status == "Success" ? true : false;

      if (this.firearmAvailability == false) {
        this.firearmAvailError = res.message;
      }
    }
  }

  @Watch("openModal", { immediate: true })
  openStateChanged(newVal: boolean): void {
    if (newVal == true) {
      this.clearSignature = false;
    }
  }

  /**
   * Magazines
   */
  numberOfMags = 1;
  /**
   * Ammunition
   */
  ammunitionRequested: number | null = null;
  ammunitionAvailable: Ammunition | null = null;
  get getAmmoAvail(): number {
    if (
      this.ammunitionAvailable == null ||
      this.ammunitionAvailable == undefined
    )
      return -1;
    return this.ammunitionAvailable.totalAvailable;
  }
  get getEnforcedSignature(): boolean {
    if (Auth.getUserOverview) {
      if (
        Auth.getUserOverview.userSignatureID == null ||
        Auth.getUserOverview.userSignatureID == undefined
      ) {
        return true;
      } else {
        return Auth.getUserOverview.securityFirm.enforceSignatures;
      }
    } else {
      return false;
    }
  }
  get getSignaturePreview(): string | null {
    /* `data:${signature.mimeType};base64,${signature.dataFiles}` */
    if (Auth.getUserOverview && Auth.getUserOverview.userSignature) {
      return `data:${Auth.getUserOverview.userSignature.mimeType};base64,${Auth.getUserOverview.userSignature.dataFiles}`;
    } else {
      return null;
    }
  }
  async callAmmoAvail(): Promise<void> {
    this.ammunitionAvailable = null;
    if (this.firearm == null) return Promise.reject("No firearm selected");
    const res = await Ammo.getFirearmAmmunition(this.firearm?.id);

    this.ammunitionAvailable = res;
    return Promise.resolve();
  }
  /**
   * Shifts
   */
  shiftSite: ShiftSiteModel | null = null;
  shiftSites: ShiftSiteModel[] = [];
  shifts: Shift[] = [];
  shift: Shift | null = null;
  showShiftDetails = false;
  async loadShiftSites(): Promise<void> {
    this.shiftSites = await ShiftSite.getShiftSites();
  }
  get getShiftSites(): ShiftSiteModel[] {
    return this.shiftSites;
  }
  async loadShifts(): Promise<void> {
    if (this.aro && this.aro.id) {
      this.shifts = await Shifts.getShifts(
        dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
        [this.aro.id.toString()]
      );
    }
  }

  onShiftChange() {
    logger.log("Shift Changed");
    if (this.shift != null) {
      this.shiftSite = this.shift.shiftSite;
      this.fromDate = dayjs(this.shift.startDate).format("YYYY-MM-DD");
      this.fromTime = this.shift.startTime;
      this.toDate = dayjs(this.shift.endDate).format("YYYY-MM-DD");
      this.toTime = this.shift.endTime;

      //this.showShiftDetails = true;
    }
  }
  /**
   * Duty
   */
  natureOfDuty = "";
  naturesOfDuty = [
    "Armed Response",
    "Special Operations",
    "Standby Shift",
    "Training",
    "Management",
    "Riot",
    "CPO",
    "Escort",
    "Anti-Poaching",
    "Armed Guard",
  ];
  /**
   * Submission
   */
  get getSubmitable() {
    var issueSignature = false;
    if (this.getEnforcedSignature == true) {
      if (this.issueSignature != null) {
        issueSignature = true;
      } else {
        issueSignature = false;
      }
    } else {
      issueSignature = true;
    }
    return (
      // Form Validity
      this.valid &&
      // ARO
      !!this.aro &&
      this.aros.includes(this.aro) &&
      // FireArm
      this.validFirearm &&
      // !!this.firearm && this.firearms.includes(this.firearm) &&
      // FireArm Availability
      this.firearmAvailability &&
      // Ammunition
      !!this.ammunitionAvailable &&
      // Location
      !!this.shiftSite /* &&
      this.shiftSites.includes(this.shiftSite)*/ &&
      // Form Validity
      this.permitCheckedBy &&
      // Duty
      !!this.natureOfDuty &&
      this.naturesOfDuty.includes(this.natureOfDuty) &&
      //issue signature
      issueSignature == true
    );
  }
  async submit(event: any): Promise<void> {
    this.loading = true;
    // Last Data Check
    if (
      this.shiftSite == null ||
      this.aro == null ||
      this.firearm == null ||
      this.ammunitionAvailable == null
    )
      return Promise.resolve();
    // Special Conditions
    const specialCond =
      this.specialCond1 == true && this.specialCond2 == true
        ? 3
        : this.specialCond1 == false && this.specialCond2 == false
        ? 0
        : this.specialCond1 == false && this.specialCond2 == true
        ? 2
        : 1;
    // Package Data
    const temp: PermitCreate = {
      ammunitionCount: this.ammunitionRequested || 0,
      fromDate: this.fromDate,
      toDate: this.toDate,
      toTime: this.toTime,
      fromTime: this.fromTime,
      natureOfDuty: this.natureOfDuty,
      armResponseOfficerID: this.aro.id ? this.aro.id : 0, // TODO@CVD Fix this to work with an undefined field.
      firearmID: this.firearm.id,
      shiftSiteID: this.shiftSite.id,
      specialCondition: specialCond,
      numberOfMagazines: this.numberOfMags,
      showAROSignature: this.addAroSignature,
      showResponsiblePersonSignature: this.addPersonSignature, // Strictly speaking this is "Issued by"
      ammunitionID: this.ammunitionAvailable.id,
      securityFirmID: this.aro.securityFirmID,
    };
    if (this.getEnforcedSignature == true && this.issueSignature != null) {
      temp.issuedBySignature = {
        fileName: "",
        fileSize: 0,
        mimeType: "",
        upload: this.issueSignature,
      };
    }
    // Transmit Data
    const res = await Permits.issuePermit(temp)
      .then(async (response) => {
        logger.log("Issue Permit Response", response);
        if (response.mimeType && response.upload && response.fileName) {
          const base64 = `data:${response.mimeType};base64,${response.upload}`;

          Downloader.saveFile(base64, response.fileName, response.mimeType);
        }
      })
      .catch(async (err) => {
        logger.log("Issue permit error :", err);
        this.openModal = false;
        this.$notifCreator.createErrorNotification(err.message);
        await this.reset(event).catch((err) => {
          return Promise.reject(err);
        });
        return Promise.reject(err);
      });
    // await this.loadAllPermits().catch((err) => {
    //   return Promise.reject(err);
    // });
    this.$emit("reloadAllPermits");
    await this.reset(event).catch((err) => {
      return Promise.reject(err);
    });
    // logger.log("Response for issue permit: ", res);
    this.openModal = false;
    this.loading = false;
    this.clearSignature = true;
    return Promise.resolve();
  }

  async reset(event: any) {
    this.loading = true;
    try {
      // - Armed Response Officer
      this.aro = null;
      // - Site
      this.shiftSite = null;
      this.shift = null;
      this.showShiftDetails = false;
      // - Firearm
      this.ammunitionRequested = null;
      this.ammunitionAvailable = null;
      this.selectFirearm(null, null);
      // Duty
      this.natureOfDuty = "";
      // Specialists
      this.specialCond1 = false;
      this.specialCond2 = false;
      this.issueSignature = null;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  validate() {
    if (this.$refs.newPermitForm) {
      (this.$refs.newPermitForm as HTMLFormElement).validate();
    }
  }

  /**
   * Initialize
   *
   * Loads the various information from the database that populates the respective drop downs
   *
   * Note: This is only invoked from mounted()
   */
  async initialize(): Promise<void> {
    // (this.$refs.newPermitForm as HTMLFormElement).reset();
    if (this.$refs.newPermitForm) {
      (this.$refs.newPermitForm as HTMLFormElement).resetValidation();
    }
    try {
      this.loading = true;
      this.valid = false;
      await this.loadAros();
      await this.loadFirearms();
      await this.loadShiftSites();
      //await this.loadShifts();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    try {
      await this.initialize();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
