import { axiosInstance } from "@/plugins/axios";
import {
  Pocketbook,
  PocketBookCollection,
  PocketbookViewModel,
  PocketbookViewModel_CREATE,
  PocketBookExtentions,
  PocketBookFilter,
  PocketBookTitleOptions,
  PocketBookNotificationViewModel,
  PocketBookEntryCheckedViewModel,
} from "models";

const PocketBook = () => {
  const baseUrl = "/api/PocketBook/";
  const GetPocketBookTitleOptions = async (): Promise<
    PocketBookTitleOptions[]
  > => {
    const res = await axiosInstance.get(baseUrl + "GetPocketBookTitleOptions");
    return Promise.resolve(res.data);
  };

  const GetPocketBookEntriesForUser = async (
    filter: PocketBookFilter
  ): Promise<PocketbookViewModel[]> => {
    const res = await axiosInstance.post(
      baseUrl + "GetPocketBookEntriesForUser",
      filter
    );
    return Promise.resolve(res.data);
  };

  const GetAllPocketBookEntriesForCompany = async (
    filter: PocketBookFilter
  ): Promise<PocketbookViewModel[]> => {
    const res = await axiosInstance.post(
      baseUrl + "GetAllPocketBookEntriesForCompany",
      filter
    );
    return Promise.resolve(res.data);
  };

  const AddNewPocketBookEntry = async (
    data: PocketbookViewModel_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "AddNewPocketBookEntry",
      data
    );
    return Promise.resolve(res.data);
  };

  const NotifyAllPocketBooks = async (
    data: PocketBookNotificationViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "NotifyAllPocketBooks",
      data
    );
    return Promise.resolve(res.data);
  };

  const UpdatePocketBookEntry = async (
    data: PocketbookViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "UpdatePocketBookEntry",
      data
    );
    return Promise.resolve(res.data);
  };

  const DeletePocketBookEntry = async (id: number): Promise<void> => {
    const res = await axiosInstance.get(
      baseUrl + `DeletePocketBookEntry?Id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const addPocketBookComments = async (data: {
    pocketBookId: number;
    comment: string | null;
    title: string | null;
    shiftStart: string | null;
    shiftEnd: string | null;
    obNumber: string | null;
  }): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "addPocketBookComments",
      data
    );
    return Promise.resolve(res.data);
  };

  const addPocketBookCommentsList = async (
    data: {
      pocketBookId: number;
      comment: string | null;
      title: string | null;
      shiftStart: string | null;
      shiftEnd: string | null;
      obNumber: string | null;
    }[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "addPocketBookCommentsList",
      data
    );
    return Promise.resolve(res.data);
  };

  const updatePocketBookComments = async (data: {
    id: number;
    pocketBookId: number;
    comment: string | null;
    title: string | null;
    obNumber: string | null;
  }): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "updatePocketBookComments",
      data
    );
    return Promise.resolve(res.data);
  };

  const MarkEntryAsChecked = async (
    data: PocketBookEntryCheckedViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "MarkEntryAsChecked", data);
    return Promise.resolve(res.data);
  };

  const GetFirmPocketBookExtentions = async (
    filter: PocketBookFilter
  ): Promise<PocketBookExtentions[]> => {
    const res = await axiosInstance.post(
      baseUrl + "getFirmPocketBookExtentions",
      filter
    );
    return Promise.resolve(res.data);
  };

  const getAllPocketBookARO = async (): Promise<PocketBookExtentions[]> => {
    const res = await axiosInstance.get(baseUrl + "getAllPocketBookARO");
    return Promise.resolve(res.data);
  };

  const CreatePocketBookCollection = async (
    data: PocketBookCollection
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "CreatePocketBookCollection"
    );
    return Promise.resolve(res.data);
  };

  const ReviewPocketBookCollections = async (): Promise<
    PocketBookCollection[]
  > => {
    const res = await axiosInstance.get(
      baseUrl + "ReviewPocketBookCollections"
    );
    return Promise.resolve(res.data);
  };

  const UpdatePocketBookCollection = async (
    data: PocketBookCollection
  ): Promise<any> => {
    const res = await axiosInstance.patch(
      baseUrl + "UpdatePocketBookCollection"
    );
    return Promise.resolve(res.data);
  };

  const DeletePocketBookCollection = async (
    data: PocketBookCollection
  ): Promise<any> => {
    const res = await axiosInstance.delete(
      baseUrl + "DeletePocketBookCollection"
    );
    return Promise.resolve(res.data);
  };

  return {
    GetPocketBookTitleOptions,
    GetAllPocketBookEntriesForCompany,
    GetPocketBookEntriesForUser,
    AddNewPocketBookEntry,
    NotifyAllPocketBooks,
    UpdatePocketBookEntry,
    DeletePocketBookEntry,
    addPocketBookComments,
    addPocketBookCommentsList,
    updatePocketBookComments,
    getAllPocketBookARO,
    // Pocket Book Collections
    GetFirmPocketBookExtentions,
    CreatePocketBookCollection,
    ReviewPocketBookCollections,
    // ReviewPocketBookCollection,
    // UpdatePocketBookCollections,
    DeletePocketBookCollection,
    // GetPocketBookForSupervisor,
    // getPocketBookByTitle
    // getPocketBookByKeyWord
    // getPocketBookAROByDate
    // getPocketBook
    // DownloadEntryFile
    // addPocketBookComments
    // addPocketBookCommentsList
    // updatePocketBookComments
    MarkEntryAsChecked,
  };
};
const temp = PocketBook();
export default temp;
