
import {
  OldPermit as PermitModel,
  OfflinePermitType,
  notificationType,
  ConfirmPermitViewModel,
  CancelReasons,
} from "models";
import { Component, Vue } from "vue-property-decorator";
import PermitExpansionPanel from "@/partials/PermitExpansionPanel.vue";
import ReturnPermit from "@/components/ReturnPermit.vue";
import NotificationSystem from "@/store/modules/NotificationSystem";
import { Permits, IndexedDB } from "@/hooks";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import {
  NOTIFICATION as NOTIFICATIONTYPE,
  DELAY as NOTIFICATIONDELAY,
  ICON as NOTIFICATIONICON,
  COLOR as NOTIFICATIONCOLOR,
} from "@/enums/notifications";
import GlobalNotifications from "./notifications/GlobalNotifications.vue";
import axios from "axios";
import Signature from "@/components/Signature.vue";
import Auth from "@/store/modules/Auth";
import logger from "@/plugins/logger";
import { PermitFilterType } from "@/enums/filterType";
import vuetify from "@/plugins/vuetify";

@Component({
  components: {
    PermitExpansionPanel,
    ReturnPermit,
    Signature,
  },
})
export default class MyPermits extends Vue {
  status = [
    {
      description: "Issued/ Pending Action",
      value: PermitFilterType.IssuedPendingAction,
      color: "default",
    },
    {
      description: "Approved",
      value: PermitFilterType.Approved,
      color: "success",
    },
    {
      description: "Returned",
      value: PermitFilterType.Returned,
      color: "warning",
    },
    {
      description: "Confirm Returned",
      value: PermitFilterType.Confirmed,
      color: "blue",
    },
    {
      description: "Declined/Cancelled",
      value: PermitFilterType.DeclinedCancelled,
      color: "error",
    },
  ];
  selectedItem = 0;
  permitFilter: string | null = null;
  loading = false;
  signatureOpen = false;
  clearSignature = false;
  /* signatureAction: "confirm" | "approve" = "approve"; */
  permitToBeHandled: null | PermitModel = null;
  signature: string | null = null;
  returnPermit: {
    open: boolean;
    permit: null | PermitModel;
  } = {
    open: false,
    permit: null,
  };
  declinePermitModal: {
    open: boolean;
    permit: PermitModel | null;
    formValid: boolean;
    reasons: CancelReasons[];
    other: string | null;
  } = {
    open: false,
    permit: null,
    formValid: false,
    reasons: [],
    other: null,
  };
  permits: PermitModel[] = [];
  sortByDate(a: PermitModel, b: PermitModel): number {
    const aDate = new Date(a.toDate).getTime();
    const bDate = new Date(b.toDate).getTime();
    if (aDate - bDate > 0) {
      return -1;
    } else if (aDate - bDate < 0) {
      return 1;
    } else {
      if (a.toTime.split(":")[0] > b.toTime.split(":")[0]) {
        return -1;
      } else if (a.toTime.split(":")[0] < b.toTime.split(":")[0]) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  async initialize(): Promise<void> {
    try {
      this.permits = await Permits.getAROPermits();
      this.permits.sort(this.sortByDate);
    } catch (err) {
      logger.log("Error ", err);
    }
  }

  /*  async handlePermit(
    action: "confirm" | "approve",
    permit: PermitModel
  ): Promise<void> {
    if (this.getEnforcedSignature == true) {
      this.signatureOpen = true;
      this.signatureAction = action;
      this.permitToBeHandled = permit;
    } else {
      if (action == "approve") {
        await this.approvePermit(permit);
      } else if (action == "confirm") {
        await this.confirmPermit(permit);
      }
    }
  } */

  async handlePermit(permit: PermitModel): Promise<void> {
    const dateCompare = dayjs();
    const splits = permit.fromDateStr.split("-");
    const permitDate = dayjs()
      .set("D", Number(splits[0]))
      .set("M", Number(splits[1]) - 1)
      .set("year", Number(splits[2]))
      .set("h", Number(permit.fromTime.split(":")[0]))
      .set("minute", Number(permit.fromTime.split(":")[1]))
      .set("second", 0);

    // permitDate.setTime(permit.fromTime);
    console.log(
      "Permit dates ",
      permit.fromTime,
      permit.fromDate,
      permit.fromDateStr
    );
    console.log("Date ", permitDate.toString());
    console.log("Date Now ", dateCompare.toString());
    debugger;
    if (dateCompare.isBefore(permitDate.toDate().getTime())) {
      this.$notifCreator.createWarningNotification(
        "Cannot approve permit before start time"
      );
      return Promise.resolve();
    }
    if (this.getEnforcedSignature == true) {
      this.signatureOpen = true;
      this.clearSignature = false;
      this.permitToBeHandled = permit;
    } else {
      await this.approvePermit(permit);
    }
  }

  get getEnforcedSignature(): boolean {
    if (Auth.getUserOverview) {
      if (
        Auth.getUserOverview.userSignatureID == null ||
        Auth.getUserOverview.userSignatureID == undefined
      ) {
        return true;
      } else {
        return Auth.getUserOverview.securityFirm.enforceSignatures;
      }
    } else {
      return false;
    }
  }
  async handleSignatureUpdate(b64: string): Promise<void> {
    logger.log("Signature: ", b64);
    if (b64 != undefined && this.permitToBeHandled != null) {
      this.signature = b64;
      /* if (this.signatureAction == "approve") { */
      await this.approvePermit(this.permitToBeHandled);
      /* } */
      /*  if (this.signatureAction == "confirm") {
        await this.confirmPermit(this.permitToBeHandled);
      } */
      this.signatureOpen = false;
      this.clearSignature = true;
    } else {
      this.$notifCreator.createErrorNotification(
        "Sorry! Something went wrong, Please try later"
      );
    }
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      await this.initialize();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  openReturnPermitModal(item: PermitModel): void {
    this.returnPermit.permit = item;
    this.returnPermit.open = true;
  }

  async openDeclinePermitModal(permit: PermitModel): Promise<void> {
    const resp = await Permits.getAllCancelReasons();
    this.declinePermitModal.reasons = resp;
    this.declinePermitModal.reasons.push({ id: 123, reason: "Other" });
    this.declinePermitModal.permit = permit;
    this.declinePermitModal.permit.declineReason = null;
    this.declinePermitModal.open = true;
  }

  async declinePermit(perm: PermitModel): Promise<void> {
    try {
      this.loading = true;
      if (this.declinePermitModal.other) {
        perm.cancelledReason = this.declinePermitModal.other;
      }
      const res = await Permits.declinePermit(perm);
      this.declinePermitModal.open = false;
      this.declinePermitModal.permit = null;
      this.initialize();
      // logger.log("Approve permit? : ", res);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  get getFilteredPermits(): PermitModel[] {
    if (this.permitFilter === null) {
      return this.permits;
    }
    const temp = this.permitFilter;
    return this.permits.filter(
      (e) =>
        e.armResponseOfficer.surname
          .toLowerCase()
          .includes(temp.toLowerCase()) ||
        e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
        e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
        e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
        e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
        e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
    );

    // this.permits.sort(this.sortByDate);
  }

  async approvePermit(perm: PermitModel): Promise<void> {
    // function permitCheckout(perm: PermitModel) {
    //   // TODO:
    //   //   This is a hacky extention to the approval time window, this can be removed when the to/from dates/times are ported to initDateTime/termDateTime
    //   // logger.log(perm.fromDate, perm.fromTime);
    //   const fromDateTime = dayjs(
    //     perm.fromDate.toString().split("T")[0] + "T" + perm.fromTime
    //   ).subtract(15, "minutes");
    //   perm.fromTime = fromDateTime.format("HH:mm");
    //   perm.fromDate = fromDateTime.toDate(); // fromDateTime.toDate().setHours(0, 0, 0, 0); || new Date(fromDateTime.year(), fromDateTime.month(), fromDateTime.date()); ?
    //   // logger.log(perm.fromDate, perm.fromTime);
    //   return perm;
    // }
    /* const datetime = dayjs();
    const checkout = dayjs(
      perm.fromDate.toString().split("T")[0] + "T" + perm.fromTime
    ).subtract(15, "minutes"); */
    if (this.getEnforcedSignature == true && this.signature != null) {
      perm.acceptedBySignature = {
        fileName: "",
        fileSize: 0,
        mimeType: "",
        upload: this.signature,
      };
    }
    /* if (checkout.isBefore(datetime)) { */
    try {
      this.loading = true;
      const res = await Permits.approvePermit(perm);
      this.initialize();
      // logger.log("Approve permit? : ", res);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    /* } else { */
    /*  logger.log("testing 123"); */
    // Hacky means of displaying popup
    // const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    // (async () => {
    //   this.notice =
    //     "Fire arms may only be checked out up to 15 minutes before time stated upon permits";
    //   await delay(3000);
    //   this.notice = null;
    // })();
    // return Promise.reject(); // TODO: Not sure about this one
    // this.snackbar.message =
    //   "Firearms may only be checked out up to 15 minutes before the permitted period";
    // this.snackbar.display = true;
    // NotificationSystem.addNotification({
    //   message: "Test error!!",
    //   color: "error",
    //   icon: "mdi-alert",
    // });
    /* this.$notifCreator.createErrorNotification(
        "Firearms may only be checked out up to 15 minutes before time stated upon permits"
      ); */
    /*   NotificationSystem.addToQueue({
        // AddNotification
        timeout: 3000,
        message:
          "Firearms may only be checked out up to 15 minutes before time stated upon permits",
        color: "error",
        icon: "mdi-error",
        id: 1,
      }); */
    /* } */
  }
  // async accessPermit(item: PermitModel): Promise<void> {
  //   try {
  //     this.loading = true;
  //     const res = await Permits.getPermitPDF(item);
  //     // .catch((err) => {
  //     //   logger.log(err);
  //     //   this.$notifCreator.createErrorNotification(
  //     //     "Unable to download permit from server"
  //     //   );
  //     //   return Promise.reject(err);
  //     // });
  //     // logger.log("Get Permit: ", res);
  //     const fileUrl = window.URL.createObjectURL(res);
  //     // window.open(fileUrl);
  //     window.location.replace(fileUrl);
  //     // const buffer = await res.arrayBuffer();
  //     // let tab = window.open();
  //     // tab.location.href = fileUrl;
  //   } catch (err) {
  //     return Promise.reject(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  /*  async confirmPermit(perm: PermitModel): Promise<void> {
    var temp: ConfirmPermitViewModel = {};
    temp.assignFirearmId = perm.id;
    if (this.getEnforcedSignature == true && this.signature != null) {
      temp.confirmedBySignature = {
        fileName: "",
        fileSize: 0,
        mimeType: "",
        upload: this.signature,
      };
    }
    try {
      this.loading = true;
      const res = await Permits.confirmPermit(temp);
      this.initialize();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  } */

  async downloadPermit(item: PermitModel): Promise<void> {
    try {
      this.loading = true;
      const res = await Permits.getPermitPDF(item);
      // .catch((err) => {
      //   logger.log(err);
      //   this.$notifCreator.createErrorNotification(
      //     "Unable to download permit from server"
      //   );
      //   return Promise.reject(err);
      // });
      // logger.log("Get Permit: ", res);
      // const fileUrl = window.URL.createObjectURL(res);
      const buffer = await res.arrayBuffer();
      const saved: OfflinePermitType = {
        date: new Date(item.createdDate).toLocaleDateString(),
        id: item.id,
        buffer: buffer,
        title: `${item.armResponseOfficer.surname}, ${item.armResponseOfficer.name}`,
        subtitle: `${item.firearm.make}, ${item.firearm.model}, ${item.firearm.typeStr}`,
      };
      if (AuthModule.getUsername != null) {
        await IndexedDB.savePermit(saved, AuthModule.getUsername);
        this.$router.push({
          name: "dash.offlinePermits",
        });
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
