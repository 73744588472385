import axios from "axios";
import Auth from "@/store/modules/Auth";
import { Notifications } from "@/hooks/Notifications";
import { Storage } from "@/hooks";
import router from "@/router";
import logger from "./logger";

const axiosNoAuth = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_API_BASE,
});
//only after signed in
const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    skipError: false,
  },
  baseURL: process.env.VUE_APP_API_BASE,
});
const generateMessageFromError = (error: unknown): string => {
  let message = "";
  if (axios.isAxiosError(error)) {
    logger.log("To json response ", error.toJSON(), error);
    if (error.response) {
      if (error.response.data && error.response.data != "") {
        // for our BE
        if (
          (error.response.data as any).status &&
          (error.response.data as any).title
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).title + `<br />`;
          const errs = (error.response.data as any).errors;
          if (errs) {
            Object.entries(errs).forEach((e) => {
              message += e[1] + `<br />`;
            });
          }
        } else if (
          (error.response.data as any).status &&
          (error.response.data as any).message
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).message;
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.statusText) {
        // general error message
        logger.log("data if else ", error.response);
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else {
        // root error
        logger.log("data else ", error.response);
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    const temp = JSON.stringify(error);
    if (temp) {
      message = temp;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    /* const message = generateMessageFromError(error);
    logger.log("Testing message :", message);
    Notifications.createErrorNotification(message); */
    if (axios.isAxiosError(error) && error.config.headers?.skipError == false) {
      logger.log("Axios inter: ", error);
      if (error.response && error.response.status == 401) {
        Notifications.createErrorNotification(
          "Login Expired, Please Login Again."
        );
        Auth.removeLoggedIn();
        await Storage.removeLogin();
        // IndexedDB.unmountDB();
        router.push({
          name: "Login",
        });
      } else {
        const message = generateMessageFromError(error);
        if (
          error.config.url &&
          error.config.url.includes("getUserTrialRun") &&
          error.response &&
          error.response.status == 403
        ) {
          //do nothing
        } else {
          Notifications.createErrorNotification(message);
        }
      }
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use((restConfig) => {
  if (Auth.isLoggedIn && Auth.getUser) {
    // is logged in
    const token = Auth.getUser.accessToken;
    // logger.log("ACces token :", token, u, Auth);
    const config = restConfig;
    if (config.headers) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  }
  return restConfig;
});

export { axiosInstance, axiosNoAuth, generateMessageFromError };
