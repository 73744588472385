
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { PocketBook } from "@/hooks";
import {
  PocketbookViewModel,
  PocketBookExtentions,
  PocketBookTitleOptions,
} from "models";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class ManagePocketBookCommentsModal extends Vue {
  @PropSync("open") aeCommentModal!: boolean;
  @PropSync("isSupervisor") supervisor!: boolean;
  @Prop() readonly data!: {
    type: string;
    field: PocketBookExtentions;
  };
  valid = false;
  loading = false;
  other: string | null = null;
  toTimePicker = false;
  toDateMenu = false;
  fromTimePicker = false;
  fromDateMenu = false;
  fromDate = dayjs().format("YYYY-MM-DD");
  toDate = dayjs().format("YYYY-MM-DD");
  fromTime = dayjs().format("HH:mm");
  toTime = dayjs().format("HH:mm");
  commentUpdated = false;
  titleList: PocketBookTitleOptions[] = [];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    maxLength: (value: string): boolean | string =>
      value.length <= 250 || "Max 30 characters",
  };

  convertDatewTime(value: string): string {
    return dayjs(value).format("YYYY-MM-DD HH:mm");
  }

  @Watch("aeCommentModal")
  async aeCommentModalChanged(): Promise<void> {
    if (this.data.type == "edit" && this.aeCommentModal == true) {
      this.fromDate = dayjs(this.data.field.shiftStart).format("YYYY-MM-DD");
      this.fromTime = dayjs(this.data.field.shiftStart).format("HH:mm");
      this.toDate = dayjs(this.data.field.shiftEnd).format("YYYY-MM-DD");
      this.toTime = dayjs(this.data.field.shiftStart).format("HH:mm");
    }
    if (this.aeCommentModal == true) {
      await this.loadTitles();

      if (this.data.type == "edit") {
        const findTitle = this.titleList.find(
          (item) => item.description == this.data.field.incidentTitle
        );
        if (findTitle == undefined) {
          this.other = this.data.field.incidentTitle;
          this.data.field.incidentTitle = "Other";
        }
      }
    }
    if (this.aeCommentModal == false) {
      this.fromDate = dayjs().format("YYYY-MM-DD");
      this.fromTime = dayjs().format("HH:mm");
      this.toDate = dayjs().format("YYYY-MM-DD");
      this.toTime = dayjs().format("HH:mm");
      this.titleList = [];
      this.other = null;
    }
  }

  async loadTitles(): Promise<void> {
    try {
      this.loading = true;
      const resp = await PocketBook.GetPocketBookTitleOptions();
      this.titleList = resp;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addItems(): Promise<void> {
    logger.log("Add PocketBook Entry Comment: ", this.data.field);

    try {
      var temp = {
        pocketBookId: 0,
        comment: this.data.field.comments,
        title: this.other ? this.other : this.data.field.incidentTitle,
        shiftStart: this.fromDate + " " + this.fromTime,
        shiftEnd: this.toDate + " " + this.toTime,
        obNumber: this.data.field.obNumber,
      };
      const res = await PocketBook.addPocketBookComments(temp);

      this.$notifCreator.createSuccessNotification("Pocketbook Entry Added.");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.aeCommentModal = false;
    }
  }

  async editItems(): Promise<void> {
    logger.log("Edit PocketBook Entry Comment: ", this.data.field);

    try {
      var temp = {
        id: this.data.field.id,
        pocketBookId: 0,
        comment: this.data.field.comments,
        title: this.other ? this.other : this.data.field.incidentTitle,
        shiftStart: this.fromDate + " " + this.fromTime,
        shiftEnd: this.toDate + " " + this.toTime,
        obNumber: this.data.field.obNumber,
      };
      const res = await PocketBook.updatePocketBookComments(temp);

      this.$notifCreator.createSuccessNotification("Pocketbook Entry Updated.");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.aeCommentModal = false;
    }
  }

  async markEntry(): Promise<void> {
    logger.log("Edit PocketBook Entry Comment: ", this.data.field);

    try {
      var temp = {
        pocketBookId: this.data.field.id,
        checked: this.data.field.checked!,
        feedback: this.data.field.feedback!,
      };
      const res = await PocketBook.MarkEntryAsChecked(temp);

      this.$notifCreator.createSuccessNotification("Entry Updated");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.aeCommentModal = false;
    }
  }
}
