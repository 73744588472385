
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import logger from "@/plugins/logger";
import { OfflinePermitType } from "models";
import VuePdfApp from "vue-pdf-app";
import { Device } from "@capacitor/device";
import { Documents } from "@/hooks";
//import { Share as CapShare } from "@capacitor/share";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Downloader } from "@/hooks";

@Component({ components: { VuePdfApp } })
export default class MultimediaViewer extends Vue {
  @PropSync("open") modalOpen!: boolean;
  @Prop() readonly data!: {
    type: "file" | "offlinePermitPDF";
    file: File | null;
    offlinePermit: OfflinePermitType | null;
  };
  platform: "ios" | "android" | "web" = "web";
  base64: string | null = null;

  get getModalTitle() {
    if (this.data.type == "file") {
      if (this.data.file != null) {
        return this.data.file.name;
      } else {
        return "N/A";
      }
    } else if (this.data.type == "offlinePermitPDF") {
      if (this.data.offlinePermit != null) {
        return this.data.offlinePermit.title;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  }
  get getOfflinePermit() {
    if (this.data.offlinePermit != null) {
      return this.data.offlinePermit.buffer;
    } else {
      return null;
    }
  }

  arrayBufferToFile(
    arrayBuffer: ArrayBuffer,
    fileName: string,
    mimeType: string
  ): File {
    //application/pdf
    // Step 1: Convert ArrayBuffer to Blob
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // Step 2: Convert Blob to File
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  async getBase64(file: Blob): Promise<string> {
    return (await Documents.getBase64(file)) as string;
  }

  async shareOfflinePDF() {
    /* if (this.data.offlinePermit && this.base64) {
      const writeResp = await Filesystem.writeFile({
        path: this.data.offlinePermit.title + ".pdf",
        data: this.base64,
        directory: Directory.Library,
      });

      let payload = {
        title: this.data.offlinePermit.title + ".pdf",
        files: [writeResp.uri],
      };
      await CapShare.share({
        ...payload,
        dialogTitle: "Download PDF",
      });
    } */
  }

  async shareFile() {
    /* if (this.data.file && this.base64) {
      const writeResp = await Filesystem.writeFile({
        path: this.data.file.name,
        data: this.base64,
        directory: Directory.Library,
      });

      let payload = {
        title: this.data.file.name,
        files: [writeResp.uri],
      };
      await CapShare.share({
        ...payload,
        dialogTitle: "Download File",
      });
    } */
  }

  async downloadImage() {
    if (
      this.data.file &&
      this.data.type == "file" &&
      this.platform == "web" &&
      this.base64
    ) {
      await Downloader.saveFile(
        this.base64,
        this.data.file.name,
        this.data.file.type
      );
    }
  }

  closeModal(): void {
    this.platform = "web";
    this.modalOpen = false;
  }

  trimString(input: string): string {
    // Check if the string length is more than 10 characters
    if (input.length > 14) {
      // Take the first 10 characters, add '...', then add the last 4 characters
      return `${input.slice(0, 7)}...${input.slice(-7)}`;
    }
    // Return the original string if it's not more than 10 characters
    return input;
  }

  @Watch("data", { immediate: true, deep: true })
  async dataChanged(): Promise<void> {
    logger.log("data:", this.data);
  }

  @Watch("modalOpen", { immediate: true })
  async openChanged(): Promise<void> {
    if (this.modalOpen == true) {
      this.platform = (await Device.getInfo()).platform;
      if (
        this.data.type == "offlinePermitPDF" &&
        this.data.offlinePermit != null &&
        this.platform != "web"
      ) {
        const file = this.arrayBufferToFile(
          this.data.offlinePermit.buffer,
          `${this.data.offlinePermit.title}.pdf`,
          "application/pdf"
        );
        this.base64 = await this.getBase64(file);
        //logger.log("base64", this.base64);
      }
      if (this.data.type == "file" && this.data.file != null) {
        const file = this.data.file;
        this.base64 = await this.getBase64(file);
        //logger.log("base64", this.base64);
      }
    }
  }
}
