
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import Docs from "@/store/modules/Documents";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  BUSINESS_PUS_DOC,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import FileMixin from "@/mixins/FileMixin.vue";
import MultimediaViewer from "@/components/MultimediaViewer.vue";
import { Device } from "@capacitor/device";
import logger from "@/plugins/logger";

interface Certificate {
  armResponseOfficerID: number;
  createdBy: string;
  createdDate: string;
  expiryDate: string;
  fileName: string;
  filePath: string;
  firearmType: string;
  id: number;
  isRegulation21Certificate: boolean;
  isRenewed: boolean;
  renewedDate: string | null;
  renewedDocumentFilePath: string | null;
  updatedBy: string;
  updatedDate: string;
  uploadDocument: boolean;
}

@Component({
  components: {
    VuePdfApp,
    MultimediaViewer,
  },
})
export default class SapsCompentency extends Mixins(FileMixin) {
  @Prop() certificate!: Certificate | null;

  // @Prop() handgun!: boolean;
  // @Prop() shotgun!: boolean;
  // @Prop() autoRifle!: boolean;
  // @Prop() selfLoadingRifle!: boolean;

  // @Prop() basicHandgun!: boolean;
  // @Prop() basicShotgun!: boolean;
  // @Prop() basicAutoRifle!: boolean;
  // @Prop() basicSelfLoadingRifle!: boolean;

  valid = false;

  isOpenExpiryDate = false;
  isOpenRenewalProofDate = false;
  certExpanded = false;
  renewalExpanded = false;

  multiMediaViewer: { open: boolean; file: File | null } = {
    open: false,
    file: null,
  };
  currentDoc: {
    SAPSCompetencyCertificateID: number;
    SAPSCompetencyAROID: number;
    IsRegulation21Certificate: boolean;
    SAPSShotgun: boolean;
    SAPSHandgun: boolean;
    SAPSSelfloadingrifle: boolean;
    SAPSManuallyoperatedrifle: boolean;
    SAPSCompetencyFile: File | null;
    SAPSCompetencyExpiryDate: string | null;
    RenewedDate: string | null;
    IsRenewed: boolean;
    RenewedDocumentFilePath: File | null;
    UploadDocument: boolean;
    DeviceId: string;
  } | null = null;
  fileToBeDeleted: { fileId: number; fileOption: number } | null = null;
  loading = false;
  URL = URL;

  // certPreview: null | string = null;
  // renewedPreview: null | string = null;
  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }

  viewMedia(file: File) {
    this.multiMediaViewer.file = file;
    this.multiMediaViewer.open = true;
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  deleteFile(file: number, fileId: number) {
    this.fileToBeDeleted = {
      fileId: fileId,
      fileOption: file,
    };

    if (this.currentDoc) {
      if (file == 1) {
        this.currentDoc.SAPSCompetencyFile = null;
        this.currentDoc.SAPSCompetencyExpiryDate = null;
        this.currentDoc.SAPSCompetencyCertificateID = 0;
      }
      if (file == 3) {
        this.currentDoc.RenewedDocumentFilePath = null;
        this.currentDoc.RenewedDate = null;
      }
    }
  }

  async processDelete() {
    try {
      this.loading = true;
      if (this.fileToBeDeleted != null) {
        const res = await Documents.deleteSAPSCompetency(
          this.fileToBeDeleted.fileId,
          this.fileToBeDeleted.fileOption
        );
        this.fileToBeDeleted = null;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.$emit("refresh");
      this.loading = false;
    }
  }

  get getRenewalRulesFile() {
    return [
      (v: File | null) => {
        if (v == null && this.currentDoc != null) {
          if (this.currentDoc.IsRenewed == true) {
            return "Required";
          } else {
            return true;
          }
        } else {
          if (
            v &&
            (v.name.includes(".png") ||
              v.name.includes(".jpg") ||
              v.name.includes(".pdf"))
          ) {
            return true;
          } else {
            return "Only .pdf, .png. jpg files are allowed";
          }
        }
      },
    ];
  }

  get getRenewalRules() {
    return [
      (v: string) => {
        if (v == null && this.currentDoc != null) {
          if (this.currentDoc.IsRenewed == true) {
            return "Required";
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    ];
  }
  validate(): void {
    //   logger.log("Outside validate");
    if (this.$refs.sapsForm) {
      //   logger.log("Outside inside");
      (this.$refs.sapsForm as any).validate();
    }
  }
  get getCertPreview() {
    // return this.psiraPreview;
    if (this.currentDoc?.SAPSCompetencyFile != null)
      return URL.createObjectURL(this.currentDoc.SAPSCompetencyFile);
    else return "";
  }
  get getRenewedPreview() {
    // return this.psiraPreview;
    if (this.currentDoc?.RenewedDocumentFilePath != null)
      return URL.createObjectURL(this.currentDoc.RenewedDocumentFilePath);
    else return "";
  }
  // get getAroDocs(): AroDocs | null {
  //   return Docs.getAroDocs;
  // }
  get getHandgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.firearmType.includes("Handgun") &&
            e.isRegulation21Certificate == false
        ).length > 0
      );
    }
    return false;
  }

  get getShotgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.firearmType.includes("Shotgun") &&
            e.isRegulation21Certificate == false
        ).length > 0
      );
    }
    return false;
  }
  get getMOR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.firearmType.includes("Manually-Operated Rifle") &&
            e.isRegulation21Certificate == false
        ).length > 0
      );
    }
    return false;
  }
  get getSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates.filter(
          (e) =>
            e != this.certificate &&
            e.firearmType.includes("Self-Loading Rifle") &&
            e.isRegulation21Certificate == false
        ).length > 0
      );
    }
    return false;
  }
  get getBusHG(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Handgun")
        ).length > 0
      );
    }
    return false;
  }
  get getBusSG(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Shotgun")
        ).length > 0
      );
    }
    return false;
  }
  get getBusSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Self-Loading Rifle")
        ).length > 0
      );
    }
    return false;
  }

  get getBusMOR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter((e) =>
          e.firearmType.includes("Manually-Operated Rifle")
        ).length > 0
      );
    }
    return false;
  }

  get getBasicHandgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.find((e) =>
          e.firearmType.includes("Handgun")
        ) != null
      );
    } else return false;
  }

  get getBasicShotgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.find((e) =>
          e.firearmType.includes("Shotgun")
        ) != null
      );
    } else return false;
  }
  get getBasicMOR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.find((e) =>
          e.firearmType.includes("Manually-Operated Rifle")
        ) != null
      );
    } else return false;
  }
  get getBasicSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.basicProficiencyCertificates.find((e) =>
          e.firearmType.includes("Self-Loading Rifle")
        ) != null
      );
    } else return false;
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  async init(): Promise<void> {
    if (this.getAroDocs != null) {
      const deviceId = (await Device.getId()).uuid;
      if (this.certificate != null) {
        const res = await Promise.all([
          Documents.getFile(this.certificate.filePath).catch((err) =>
            Promise.resolve(null)
          ),
          Documents.getFile(this.certificate.renewedDocumentFilePath).catch(
            (err) => Promise.resolve(null)
          ),
        ]);
        this.currentDoc = {
          SAPSCompetencyCertificateID: this.certificate.id,
          SAPSCompetencyAROID: this.certificate.armResponseOfficerID,
          SAPSCompetencyFile: res[0],
          IsRegulation21Certificate: this.certificate.isRegulation21Certificate,
          SAPSShotgun: this.certificate.firearmType.includes("Shotgun"),
          SAPSHandgun: this.certificate.firearmType.includes("Handgun"),
          SAPSManuallyoperatedrifle: this.certificate.firearmType.includes(
            "Manually-Operated Rifle"
          ),
          SAPSSelfloadingrifle:
            this.certificate.firearmType.includes("Self-Loading Rifle"),
          SAPSCompetencyExpiryDate: dayjs(this.certificate.expiryDate).format(
            "YYYY-MM-DD"
          ),
          RenewedDate:
            this.certificate.renewedDate != null
              ? dayjs(this.certificate.renewedDate).format("YYYY-MM-DD")
              : null,
          RenewedDocumentFilePath: res[1],
          IsRenewed: this.certificate.isRenewed,
          UploadDocument:
            this.certificate.uploadDocument != null
              ? this.certificate.uploadDocument
              : res[0] != null || res[1] != null
              ? true
              : false,
          DeviceId: deviceId,
        };
      } else {
        this.currentDoc = {
          SAPSCompetencyCertificateID: 0,
          SAPSCompetencyAROID: this.getAroDocs.id,
          SAPSCompetencyFile: null,
          IsRegulation21Certificate: false,
          SAPSShotgun: false,
          SAPSHandgun: false,
          SAPSManuallyoperatedrifle: false,
          SAPSSelfloadingrifle: false,
          SAPSCompetencyExpiryDate: null,
          RenewedDate: null,
          RenewedDocumentFilePath: null,
          IsRenewed: false,
          UploadDocument: true,
          DeviceId: deviceId,
        };
      }

      this.fileToBeDeleted = null;
    }
  }
  async mounted(): Promise<void> {
    //   logger.log("Mounted Saps COmpenecye", this.certificate);
    await this.init();
  }
  async addSAPS(): Promise<void> {
    try {
      this.loading = true;
      const formData = new FormData();
      if (this.currentDoc != null) {
        Object.entries(this.currentDoc).forEach(([key, value]) => {
          if (value != null) {
            if (this.currentDoc?.IsRenewed == false) {
              //   logger.log("Regulation keys : ", key, value);
              if (key !== "RenewedDocumentFilePath" && key !== "RenewedDate") {
                if (typeof value === "number" || typeof value === "boolean") {
                  formData.append(key, value.toString());
                } else {
                  formData.append(key, value);
                }
              }
            } else {
              //   logger.log("SAPS renewed true");
              if (typeof value === "number" || typeof value === "boolean") {
                formData.append(key, value.toString());
              } else formData.append(key, value);
            }
          }

          // if (value == null) {
          //   formData.append(key, "");
          // } else if (typeof value === "number" || typeof value === "boolean") {
          //   formData.append(key, value.toString());
          // } else formData.append(key, value);
        });

        /* for (var pair of formData.entries()) {
          //   logger.log("Form - ", pair[0] + ", " + pair[1]);
        } */

        await this.processDelete();

        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addSAPSCompetency",
          formData
        );

        //   logger.log("Add Saps response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  @Watch("certificate", {
    immediate: true,
  })
  async onCertChanged(val: Certificate | null): Promise<void> {
    //   logger.log("Sor changed from ", oldVal, " to ", val);
    await this.init();
  }
}
