
import { Component, Vue, Watch } from "vue-property-decorator";
import PermitExpansionPanel from "@/partials/PermitExpansionPanel.vue";
import ReturnPermit from "@/components/ReturnPermit.vue";
import IssuePermit from "@/views/manage/permits/IssuePermit.vue";
import AuthModule from "@/store/modules/Auth";
import JsonExcel from "@/components/JsonExcel.vue";
import {
  Permits,
  IndexedDB,
  CRUDFunctionality,
  AROs,
  Downloader,
  ShiftSite,
  Documents,
  Firearms,
} from "@/hooks";
import Signature from "@/components/Signature.vue";
import {
  AROViewModel,
  CancelReasons,
  ConfirmPermitViewModel,
  Firearm,
  OfflinePermitType,
  OldPermit,
  ShiftSite as ShiftSiteModel,
} from "models";
import dayjs from "dayjs";
import Auth from "@/store/modules/Auth";
import { filter } from "vue/types/umd";
import logger from "@/plugins/logger";
import { PermitFilterType } from "@/enums/filterType";

@Component({
  components: {
    PermitExpansionPanel,
    ReturnPermit,
    IssuePermit,
    JsonExcel,
    Signature,
  },
})
export default class ManagePermits extends Vue {
  permitFilter: string | null = null;
  sortDesc = true;
  //loading = false;
  bulkDownloadLoading = false;
  newPermitModal = false;
  selectedItem = 0;
  reports: OldPermit[] = [];
  permitsFiltered: OldPermit[] = [];
  clearSignature = false;
  loaders = { general: false, aros: false, firearms: false, sites: false };
  status = [
    {
      description: "Issued/ Pending Action",
      value: PermitFilterType.IssuedPendingAction,
      color: "default",
    },
    {
      description: "Approved",
      value: PermitFilterType.Approved,
      color: "success",
    },
    {
      description: "Returned",
      value: PermitFilterType.Returned,
      color: "warning",
    },
    {
      description: "Confirm Returned",
      value: PermitFilterType.Confirmed,
      color: "blue",
    },
    {
      description: "Declined/Cancelled",
      value: PermitFilterType.DeclinedCancelled,
      color: "error",
    },
  ];
  filterOnStatus: PermitFilterType = PermitFilterType.IssuedPendingAction;
  cancelPermitModal: {
    open: boolean;
    permit: OldPermit | null;
    formValid: boolean;
    reasons: CancelReasons[];
    other: string | null;
  } = {
    open: false,
    permit: null,
    formValid: false,
    reasons: [],
    other: null,
  };
  firearmList: Firearm[] = [];
  firearmsSelected: Firearm[] = [];
  aros: AROViewModel[] = [];
  arosSelected: AROViewModel[] = [];
  get userID() {
    return AuthModule.getUserOverview?.id;
  }
  shiftSites: ShiftSiteModel[] = [];
  shiftSitesSelected: ShiftSiteModel[] = [];
  outstandingPermits = false;
  permits: OldPermit[] = [];
  permitDatePicker = false;
  dateFilters = {
    to: {
      active: false,
      date: dayjs().format("YYYY-MM-DD"),
    },
    from: {
      active: false,
      date: dayjs() /* .subtract(4, "days") */
        .format("YYYY-MM-DD"),
    },
  };
  signatureOpen = false;
  permitToBeHandled: null | OldPermit = null;
  signature: string | null = null;
  returnPermit: {
    open: boolean;
    permit: null | OldPermit;
  } = {
    open: false,
    permit: null,
  };
  currentPdfBuffer: ArrayBuffer | null = null;
  currentPdf: Blob | null = null;
  sortByDate(a: OldPermit, b: OldPermit): number {
    const aDate = new Date(a.toDate).getTime();
    const bDate = new Date(b.toDate).getTime();
    if (aDate - bDate > 0) {
      if (this.sortDesc) return -1;
      else return 1;
    } else if (aDate - bDate < 0) {
      if (this.sortDesc) return 1;
      else return -1;
    } else {
      if (a.toTime.split(":")[0] > b.toTime.split(":")[0]) {
        if (this.sortDesc) return -1;
        else return 1;
      } else if (a.toTime.split(":")[0] < b.toTime.split(":")[0]) {
        if (this.sortDesc) return -1;
        else return 1;
      } else {
        return 0;
      }
    }
  }

  expReports: any[] = [];

  expFields = {
    "Permit Number": "Permit Number",
    ARO: "ARO",
    Firearm: "Firearm",
    "From Date": "From Date",
    "To Date": "To Date",
    "Firearm S/N": "Firearm S/N",
    "Ammunition Calibre": "Ammunition Calibre",
    "Ammunition Count": "Ammunition Count",
    "From Time": "From Time",
    "To Time": "To Time",
    Area: "Area",
    "Nature Of Duty": "Nature Of Duty",
    "Number Of Magazines": "Number Of Magazines",
    Remarks: "Remarks",
    Returned: "Returned",
    "Returned By": "Returned By",
    "Returned Date": "Returned Date",
    "Ammunition Return": "Ammunition Return",
    "Show ARO Signature": "Show ARO Signature",
    "Show Responsible Person Signature": "Show Responsible Person Signature",
    "Approved By": "Approved By",
    "Approved Date": "Approved Date",
    "Declined By": "Declined By",
    "Declined Date": "Declined Date",
  };

  // elem.firearm.make != null ||
  //           elem.firearm.model != null ||
  //           elem.firearm.type != null ||
  //           elem.firearm.expiryDateStr != null
  //             ? elem.make +
  //               ", " +
  //               elem.model +
  //               ", " +
  //               elem.type +
  //               ", " +
  //               elem.expiryDateStr
  //             : " ",

  // elem.armResponseOfficer.name != null || elem.armResponseOfficer.surname != null
  //           ?

  populateExportData(items: any[]) {
    return items.map((elem) => {
      return {
        "Permit Number":
          elem.savedPermitNumber != null ? elem.savedPermitNumber : "",
        ARO:
          (elem.armResponseOfficer.name != null
            ? elem.armResponseOfficer.name
            : "") +
          ", " +
          (elem.armResponseOfficer.surname != null
            ? elem.armResponseOfficer.surname
            : ""),

        Firearm:
          (elem.firearm.make != null ? elem.firearm.make : "") +
          ", " +
          (elem.firearm.model != null ? elem.firearm.model : "") +
          ", " +
          (elem.firearm.type != null ? elem.firearm.type : "") +
          ", " +
          (elem.firearm.expiryDateStr != null
            ? elem.firearm.expiryDateStr
            : ""),
        "From Date": elem.fromDateStr != null ? elem.fromDateStr : "",
        "To Date": elem.toDateStr != null ? elem.toDateStr : "",
        "Firearm S/N": elem.firearmID != null ? elem.firearmID : "",
        "Ammunition Calibre":
          elem.ammunition.calibre != null ? elem.ammunition.calibre : "",
        "Ammunition Count":
          elem.ammunitionCount != null ? elem.ammunitionCount : "",
        "From Time": elem.fromTime != null ? elem.fromTime : "",
        "To Time": elem.toTime != null ? elem.toTime : "",
        Area: elem.shiftSite.name != null ? elem.shiftSite.name : "",
        "Nature Of Duty": elem.natureOfDuty != null ? elem.natureOfDuty : "",
        "Number Of Magazines":
          elem.numberOfMagazines != null ? elem.numberOfMagazines : "",
        Remarks:
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.remarks != null
              ? elem.ammunitionReturn.remarks
              : ""
            : "N/A",
        Returned:
          elem.isReturned != null
            ? elem.isReturned == true
              ? "Yes"
              : "No"
            : "",
        "Returned By":
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.createdBy != null
              ? elem.ammunitionReturn.createdBy
              : ""
            : "",

        "Returned Date":
          elem.ammunitionReturn != null
            ? dayjs(elem.ammunitionReturn.createdDate).format("YYYY-MM-DD")
            : "",
        "Ammunition Return":
          elem.ammunitionReturn != null
            ? "(total - used = returned) " +
              (elem.ammunitionReturn.totalAllocated != null
                ? elem.ammunitionReturn.totalAllocated
                : "") +
              " - " +
              (elem.ammunitionReturn.usedQuantity != null
                ? elem.ammunitionReturn.usedQuantity
                : "") +
              " = " +
              (elem.ammunitionReturn.returnQuantity != null
                ? elem.ammunitionReturn.returnQuantity
                : "")
            : " REQUIRED",

        "Show ARO Signature":
          elem.showAROSignature != null
            ? elem.showAROSignature == true
              ? "Yes"
              : "No"
            : "",
        "Show Responsible Person Signature":
          elem.showResponsiblePersonSignature != null
            ? elem.showResponsiblePersonSignature == true
              ? "Yes"
              : "No"
            : "",
        "Approved By":
          elem.acceptedBy != null
            ? elem.acceptedBy.userName != null
              ? elem.acceptedBy.userName
              : ""
            : "",
        "Approved Date":
          elem.acceptedDate != null
            ? dayjs(elem.acceptedDate).format("YYYY-MM-DD")
            : "",
        "Declined By": elem.declineBy != null ? elem.declineBy : "",
        "Declined Date":
          elem.declinedDate != null
            ? dayjs(elem.declinedDate).format("YYYY-MM-DD")
            : "",
      };
    });
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      logger.log("PDF Triggered");
      const columns = [
        { header: "Permit #", dataKey: "Permit Number" },
        { header: "ARO", dataKey: "ARO" },
        { header: "Firearm", dataKey: "Firearm" },
        { header: "From Date", dataKey: "From Date" },
        { header: "To Date", dataKey: "To Date" },
        { header: "Firearm S/N", dataKey: "Firearm S/N" },
        { header: "Ammunition Calibre", dataKey: "Ammunition Calibre" },
        { header: "Ammunition Count", dataKey: "Ammunition Count" },
        { header: "From Time", dataKey: "From Time" },
        { header: "To Time", dataKey: "To Time" },
        { header: "Area", dataKey: "Area" },
        { header: "Nature Of Duty", dataKey: "Nature Of Duty" },
        { header: "Number Of Magazines", dataKey: "Number Of Magazines" },
        { header: "Remarks", dataKey: "Remarks" },
        { header: "Returned", dataKey: "Returned" },
        { header: "Returned By", dataKey: "Returned By" },
        { header: "Returned Date", dataKey: "Returned Date" },
        { header: "Ammunition Return", dataKey: "Ammunition Return" },
        { header: "Show ARO Signature", dataKey: "Show ARO Signature" },
        {
          header: "Show Responsible Person Signature",
          dataKey: "Show Responsible Person Signature",
        },
        { header: "Approved By", dataKey: "Approved By" },
        { header: "Approved Date", dataKey: "Approved Date" },
        { header: "Declined By", dataKey: "Declined By" },
        { header: "Declined Date", dataKey: "Declined Date" },
      ];

      logger.log("PDF Columns generated");
      await Downloader.generatePdfAndSave({
        fileName: `Permit (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.getExpData,
        horizontalPageBreakRepeat: "Make",
      });

      logger.log("PDF Exported");
    } catch (err) {
      //comment

      logger.log("PDF Err: ", err);
    }
  }

  sortByStatus(a: OldPermit, b: OldPermit): number {
    const aRet = a.ammunitionReturn == null && a.declineBy == null;
    const bRet = b.ammunitionReturn == null && b.declineBy == null;

    // item.ammunitionReturn == null &&
    // item.declineBy == null,
    if (aRet < bRet) {
      // if (this.sortDesc) return -1;
      // else return 1;
      return 1;
    } else if (aRet > bRet) {
      // if (this.sortDesc) return 1;
      // else return -1;
      return -1;
    } else {
      return 0;
    }
  }
  // get getFilteredPermits1(): OldPermit[] {
  //   if (this.permitFilter === null) {
  //     return this.permits.sort((a: OldPermit, b: OldPermit) =>
  //       this.sortByDate(b, a)
  //     );
  //   }
  //   const temp = this.permitFilter;
  //   return this.permits.filter(
  //     (e) =>
  //       e.armResponseOfficer.surname
  //         .toLowerCase()
  //         .includes(temp.toLowerCase()) ||
  //       e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
  //   );
  // }

  get getExpData() {
    return this.populateExportData(this.getFilteredPermits);
  }

  get getFilteredPermits(): OldPermit[] {
    let items = this.permits;

    items = items.sort(this.sortByDate).sort(this.sortByStatus);

    return items;

    // const temp = this.permitFilter;
    // return this.permits.filter(
    //   (e) =>
    //     e.armResponseOfficer.surname
    //       .toLowerCase()
    //       .includes(temp.toLowerCase()) ||
    //     e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
    // );
  }
  issuePermit(permissions: string[]): boolean {
    logger.log(
      "Firearm Permission",
      permissions,
      permissions
        .map((permission) => CRUDFunctionality.hasAccess(permission))
        .every(Boolean)
    );
    return permissions
      .map((permission) => CRUDFunctionality.hasAccess(permission))
      .every(Boolean);
  }
  // get getIssuePermit(): boolean {
  //   logger.log("Firearm Proxy", AuthModule.getIssuePermit);
  //   logger.log("Firearm Permission", CRUDFunctionality.hasAccess("addPermit"));
  //   return AuthModule.getIssuePermit;
  // }
  async downloadPermit(item: OldPermit): Promise<void> {
    try {
      this.loaders.general = true;
      const res = await Permits.getPermitPDF(item);
      // logger.log("Get Permit: ", res);
      // const fileUrl = window.URL.createObjectURL(res);
      const buffer = await res.arrayBuffer();
      const saved: OfflinePermitType = {
        date: new Date(item.createdDate).toLocaleDateString(),
        id: item.id,
        buffer: buffer,
        title: `${item.armResponseOfficer.surname}, ${item.armResponseOfficer.name}`,
        subtitle: `${item.firearm.make}, ${item.firearm.model}, ${item.firearm.typeStr}`,
      };
      if (AuthModule.getUsername != null) {
        await IndexedDB.savePermit(saved, AuthModule.getUsername);
        this.$router.push({
          name: "dash.offlinePermits",
        });
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.general = false;
    }
  }

  /* async getFileFromb64(b64: string, fileName: string): Promise<File> {
    const filetype = Documents.detectMimeType(b64);
    const int8Array = await fetch(b64)
      .then((res) => res.arrayBuffer())
      .then((buffer) => new Uint8Array(buffer));
    const file = new File(
      [new Blob([int8Array], { type: filetype })],
      fileName,
      {
        type: filetype,
      }
    );
    return Promise.resolve(file);
  } */

  async downloadBlankPermit(item: OldPermit): Promise<void> {
    try {
      this.loaders.general = true;
      const res = await Permits.getBlankPermitPDF(item.id);

      if (res) {
        const bs64 = `data:application/pdf;base64,${res.upload}`;
        Downloader.saveFile(bs64, res.fileName, res.mimeType);
        this.$notifCreator.createSuccessNotification("Permit Downloaded.");
      } else {
        this.$notifCreator.createErrorNotification(
          "Something went wrong while downloading. If this problem persists, please contact us."
        );
      }
      // logger.log("Get Permit: ", res);
      // const fileUrl = window.URL.createObjectURL(res);
      /* const buffer = await res.arrayBuffer();
      const saved: OfflinePermitType = {
        date: new Date(item.createdDate).toLocaleDateString(),
        id: item.id,
        buffer: buffer,
        title: `${item.armResponseOfficer.surname}, ${item.armResponseOfficer.name}`,
        subtitle: `${item.firearm.make}, ${item.firearm.model}, ${item.firearm.typeStr}`,
      };
      if (AuthModule.getUsername != null) {
        await IndexedDB.savePermit(saved, AuthModule.getUsername);
        this.$router.push({
          name: "dash.offlinePermits",
        });
      } */
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.general = false;
    }
  }

  openReturnPermitModal(item: OldPermit): void {
    this.returnPermit.permit = item;
    this.returnPermit.open = true;
  }

  // filterByStatus() {
  //   if (this.filterOnStatus != null && this.filterOnStatus != null) {
  //     const filteredPermits: OldPermit[] = [];

  //     if (this.filterOnStatus == PermitFilterType.IssuedPendingAction) {
  //       const tempIssued = this.permits.filter(
  //         (perm) =>
  //           perm.acceptedBy == null &&
  //           perm.isReturned == false &&
  //           perm.declineBy == null &&
  //           perm.cancelledBy == null
  //       );
  //       if (tempIssued != undefined) {
  //         tempIssued.map((tempI) => filteredPermits.push(tempI));
  //       }
  //     }

  //     if (this.filterOnStatus == PermitFilterType.Returned) {
  //       const tempReturned = this.permits.filter(
  //         (perm) =>
  //           perm.declineBy == null &&
  //           perm.cancelledBy == null &&
  //           perm.isReturned == true
  //       );
  //       if (tempReturned != undefined) {
  //         tempReturned.map((tempR) => filteredPermits.push(tempR));
  //       }
  //     }
  //     if (this.filterOnStatus == PermitFilterType.Confirmed) {
  //       const tempReturned = this.permits.filter(
  //         (perm) =>
  //           perm.declineBy == null &&
  //           perm.cancelledBy == null &&
  //           perm.isReturned == true &&
  //           perm.confirmedByID != null
  //       );
  //       if (tempReturned != undefined) {
  //         tempReturned.map((tempR) => filteredPermits.push(tempR));
  //       }
  //     }

  //     if (this.filterOnStatus == PermitFilterType.Approved) {
  //       logger.log("filtering by approved");
  //       const tempAccepted = this.permits.filter(
  //         (perm) =>
  //           perm.declineBy == null &&
  //           perm.cancelledBy == null &&
  //           perm.isReturned == false &&
  //           perm.acceptedBy != null
  //       );
  //       if (tempAccepted != undefined) {
  //         tempAccepted.map((tempA) => filteredPermits.push(tempA));
  //       }
  //     }

  //     if (this.filterOnStatus == PermitFilterType.DeclinedCancelled) {
  //       const tempDeclined = this.permits.filter(
  //         (perm) => perm.declineBy != null || perm.cancelledBy != null
  //       );
  //       if (tempDeclined != undefined) {
  //         tempDeclined.map((tempD) => filteredPermits.push(tempD));
  //       }
  //     }

  //     this.permits = filteredPermits;
  //   }
  // }

  async downloadAllPermits(): Promise<void> {
    this.bulkDownloadLoading = true;
    const res = await Permits.bulkDownloadPermitPDFs(
      this.filterOnStatus,
      {
        startDate: this.dateFilters.from.date,
        endDate: this.dateFilters.to.date,
      },
      this.shiftSitesSelected.map((e) => e.id!),
      this.arosSelected.map((e) => e && e.id!.toString()),
      [] // TODO@CVD: Check the filtering here
    )
      .then(async (response) => {
        logger.log(response);
        const base64 = `data:${response.mimeType};base64,${response.upload}`;

        Downloader.saveFile(base64, response.fileName, response.mimeType);
      })
      .catch((err) => {
        logger.log("Error bulk download permits: ", err);
        return Promise.reject(err);
      })
      .finally(() => {
        this.bulkDownloadLoading = false;
      });
  }

  async loadAllPermits(): Promise<void> {
    logger.log("Shift Sites", this.shiftSitesSelected);
    logger.log("AROs", this.arosSelected);
    logger.log("Firearms", this.firearmsSelected);
    const res = await Permits.getAllPermits(
      this.filterOnStatus,
      {
        startDate: this.outstandingPermits ? null : this.dateFilters.from.date,
        endDate: this.outstandingPermits ? null : this.dateFilters.to.date,
      },
      this.shiftSitesSelected.map((e) => e.id!),
      this.arosSelected.map((e) => e && e.id!.toString()),
      this.firearmsSelected.map((e) => e && e.id!)
    ).catch((err) => {
      logger.log("Error all permits: ", err);
      return Promise.reject(err);
    });
    this.permits = res;
    // this.filterByStatus();
  }
  async initialize(): Promise<void> {
    try {
      //this.loading = true;
      //loading permits
      /* await this.loadAllPermits(); */
      await this.loadAros();
      await this.loadShiftSites();
      await this.loadFirearms();
    } catch (err) {
      return Promise.reject(err);
    } /* finally {
      this.loading = false;
    } */
  }
  async loadAros(): Promise<void> {
    try {
      this.loaders.aros = true;

      const res = await AROs.getAros();
      this.aros = res.arOs;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.aros = false;
    }
  }
  async loadShiftSites(): Promise<void> {
    try {
      this.loaders.sites = true;

      this.shiftSites = await ShiftSite.getShiftSites();

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.sites = false;
    }
  }
  async loadFirearms(): Promise<void> {
    try {
      this.loaders.firearms = true;

      const res = await Firearms.getAllFirearms(false);
      this.firearmList = res;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.firearms = false;
    }
  }
  async handlePermit(permit: OldPermit): Promise<void> {
    if (this.getEnforcedSignature == true) {
      this.signatureOpen = true;
      this.clearSignature = false;
      this.permitToBeHandled = permit;
    } else {
      await this.confirmPermit(permit);
    }
  }

  async handleSignatureUpdate(b64: string): Promise<void> {
    logger.log("Signature: ", b64);
    if (b64 != undefined && this.permitToBeHandled != null) {
      this.signature = b64;
      /* if (this.signatureAction == "approve") { */
      await this.confirmPermit(this.permitToBeHandled);
      /* } */
      /*  if (this.signatureAction == "confirm") {
        await this.confirmPermit(this.permitToBeHandled);
      } */
      this.signatureOpen = false;
      this.clearSignature = true;
    } else {
      this.$notifCreator.createErrorNotification(
        "Sorry! Something went wrong, Please try later"
      );
    }
  }

  get getEnforcedSignature(): boolean {
    if (Auth.getUserOverview) {
      if (
        Auth.getUserOverview.userSignatureID == null ||
        Auth.getUserOverview.userSignatureID == undefined
      ) {
        return true;
      } else {
        return Auth.getUserOverview.securityFirm.enforceSignatures;
      }
    } else {
      return false;
    }
  }
  async confirmPermit(perm: OldPermit): Promise<void> {
    var temp: ConfirmPermitViewModel = {};
    temp.assignFirearmId = perm.id;
    if (this.getEnforcedSignature == true && this.signature != null) {
      temp.confirmedBySignature = {
        fileName: "",
        fileSize: 0,
        mimeType: "",
        upload: this.signature,
      };
    }
    logger.log("permit to be confirmed: ", temp);
    try {
      this.loaders.general = true;
      const res = await Permits.confirmPermit(temp);
      //await this.initialize();
      await this.loadAllPermits();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.general = false;
    }
  }

  async openCancelPermitModal(permit: OldPermit): Promise<void> {
    const resp = await Permits.getAllCancelReasons();
    this.cancelPermitModal.reasons = resp;
    this.cancelPermitModal.reasons.push({ id: 123, reason: "Other" });
    this.cancelPermitModal.permit = permit;
    this.cancelPermitModal.permit.cancelledReason = null;
    this.cancelPermitModal.open = true;
  }

  async cancelPermit(perm: OldPermit): Promise<void> {
    try {
      this.loaders.general = true;
      if (this.cancelPermitModal.other) {
        perm.cancelledReason = this.cancelPermitModal.other;
      }
      const res = await Permits.cancelPermit(perm);
      this.cancelPermitModal.open = false;
      this.cancelPermitModal.permit = null;
      //await this.initialize();
      await this.loadAllPermits();
      // logger.log("Approve permit? : ", res);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loaders.general = false;
    }
  }

  async mounted(): Promise<void> {
    try {
      await this.initialize();
      /*  if (AuthModule.getUsername != null) {
        const storedPermits = await IndexedDB.getStoredPermits(
          AuthModule.getUsername
        );
      } */
      this.expReports = this.populateExportData(this.getFilteredPermits);

      // await this.loadAllPermits();
      return Promise.resolve();
      // logger.log("Result of Stored PErmits: ", storedPermits);
      // logger.log("All Done setup");
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
