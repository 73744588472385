
import { Component, Vue } from "vue-property-decorator";
import { Downloader, Reports } from "@/hooks";
import { ARO_SOReport } from "models";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
})
export default class Incident_Report extends Vue {
  reports: ARO_SOReport[] = [];
  tempReports: ARO_SOReport[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "isSecurityGuard";
  expanded: ARO_SOReport[] = [];
  headers = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Full Name", value: "name" },
    { text: "ID Number", value: "idNumber" },
    { text: "PSIRA Number", value: "psiraRegistrationNumber" },
    { text: "Cell Phone Number", value: "contactNumber" },
    { text: "Email", value: "user.email" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "ARO/SO": string;
    "Full Name": string;
    "ID Number": string;
    "PSIRA Number": number;
    "Cell Phone Number": string;
    Email: string;
    "Report To": string;
    Team: string;
  }[] = [];
  expFields = {
    "ARO/SO": "isSecurityGuard",
    Name: "name",
    Surname: "surname",
    "ID Number": "idNumber",
    "PSIRA Number": "psiraRegistrationNumber",
    "Cell Phone Number": "contactNumber",
    Email: "user.email",
  };
  updateReportsFiltered(event: any) {
    this.tempReports = event ? (event as ARO_SOReport[]) : this.reports;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.tempReports);
  }
  async generatePDF(): Promise<void> {
    try {
      console.log("Testing generate", this.expReports);
      // this.loading.pdf = true;
      const columns = [
        { header: "ARO/SO", dataKey: "ARO/SO" },
        { header: "Full Name", dataKey: "Full Name" },
        { header: "ID Number", dataKey: "ID Number" },
        { header: "PSIRA Number", dataKey: "PSIRA Number" },
        { header: "Cell Phone Number", dataKey: "Cell Phone Number" },
        { header: "Email", dataKey: "Email" },
        { header: "Report To", dataKey: "Report To" },
        { header: "Team", dataKey: "Team" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `ARO-SO Report (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "Batch Number",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const res = await Reports.getARO_SOReport();
      this.reports = res;
      this.expReports = this.populateExportData(this.reports);
      this.loading = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  populateExportData(reports: ARO_SOReport[]) {
    return reports.map((elem) => {
      return {
        "ARO/SO":
          elem.isSecurityGuard != null
            ? elem.isSecurityGuard == true
              ? "SO"
              : "ARO"
            : "",
        "Full Name":
          elem.name != null || elem.surname != null
            ? elem.name + ", " + elem.surname
            : " ",
        "ID Number": elem.idNumber != null ? elem.idNumber : " ",
        "PSIRA Number":
          elem.psiraRegistrationNumber != null
            ? elem.psiraRegistrationNumber
            : " ",
        "Cell Phone Number":
          elem.contactNumber != null ? elem.contactNumber : " ",
        Email: elem.user != null ? elem.user.email : "N/A",
        "Report To":
          elem.manageUser.name != null || elem.manageUser.surname != null
            ? elem.manageUser.name + ", " + elem.manageUser.surname
            : " ",
        Team:
          elem.teamArmResponseOfficers.team != null
            ? elem.teamArmResponseOfficers.team.name
            : "N/A",
      };
    });
  }
}
