
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  DrivingLicense_DOC,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
  AROSignatureViewModel,
  SignatureViewModel,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import Signature from "@/components/Signature.vue";
import logger from "@/plugins/logger";
import MultimediaViewer from "@/components/MultimediaViewer.vue";
import { Device } from "@capacitor/device";

interface DrivingLicense {
  armResponseOfficerID: number;
  createdBy: string;
  createdDate: string;
  expiryDate: string;
  expiryDateStr: string;
  fileName: string;
  id: number;
  imagePath: string;
  isRenewed: boolean;
  renewedDate: string | null;
  renewedDocumentFilePath: string | null;
  updatedBy: string;
  updatedDate: string;
  UploadDocument: boolean;
  DeviceId: string;
}
interface DisplayDriverLisc {
  data: DrivingLicense;
  isRenewed: boolean;
  expanded: boolean;
  preview: {
    src: string;
    file: File | null;
    date: string | null;
    open: boolean;
  };
  previewRenewed: {
    src: string;
    file: File | null;
    date: string | null;
    open: boolean;
  };
}

interface DisplayIDDoc {
  data: ID_DOC;
  expanded: boolean;
  preview: {
    src: string;
    file: File | null;
  };
}
interface DisplayAdhoc {
  data: ADHOC_DOC;
  file: File | null;
  src: string;
  expanded: boolean;
  description: string | null;
}
interface ADHOC_DOC {
  armResponseOfficerID: number;
  createdBy: string;
  createdDate: string;
  description: string;
  fileName: string;
  filePath: string;
  id: number;
  updatedBy: string;
  updatedDate: string;
}
interface ID_DOC {
  armResponseOfficerID: number;
  createdBy: string;
  createdDate: string;
  fileName: string;
  filePath: string;
  id: number;
  updatedBy: string;
  updatedDate: string;
}

interface DisplaySignature_DOC extends AROSignatureViewModel {
  preview: {
    src: string;
    file: File | null;
  };
  expanded: boolean;
}

interface Certificate {
  armResponseOfficerID: number;
  certificateFilesPath: string;
  certificates: string[];
  certificatesPath: string[];
  createdBy: string;
  createdDate: string;
  firearmType: string;
  id: number;
  isTacticalUnitStandards: boolean;
  statementOfResultFilesPath: string;
  statements: string[];
  statementsPath: string[];
  updatedBy: string;
  updatedDate: string;
}

@Component({
  components: {
    VuePdfApp,
    Signature,
    MultimediaViewer,
  },
})
export default class BasicPersonalDocs extends Mixins(FileMixin) {
  validDL = false;
  validDLUpdate = false;
  validAdhocAdd = false;
  newIDvalid = false;

  newID: {
    file: File | null;
    expanded: boolean;
  } = {
    file: null,
    expanded: false,
  };
  newDl: {
    file: File | null;
    fileSrc: string;
    isOpenExpiryDate: boolean;
    expiryDate: string | null;
    isRenewed: boolean;
    isOpenRenewedDate: boolean;
    renewedDate: string | null;
    renewedSrc: string;
    renewedFile: File | null;
    expanded: boolean;
    UploadDocument: boolean;
    DeviceId: string;
  } = {
    file: null,
    isRenewed: false,
    isOpenExpiryDate: false,
    isOpenRenewedDate: false,
    expiryDate: null,
    renewedDate: null,
    renewedFile: null,
    renewedSrc: "",
    fileSrc: "",
    expanded: false,
    UploadDocument: true,
    DeviceId: "",
  };
  newAdhoc: {
    file: File | null;
    src: string;
    description: string | null;
    expanded: boolean;
  } = {
    file: null,
    src: "",
    description: null,
    expanded: false,
  };
  newSignature: {
    file: string | null;
    expanded: boolean;
  } = {
    file: null,
    expanded: false,
  };
  loading = {
    id: false,
    adhoc: false,
    drivingLic: false,
    signature: false,
  };
  URL = URL;
  currentDoc: {
    idDocs: DisplayIDDoc[];
    adHocDocs: DisplayAdhoc[];
    drivingLic: DisplayDriverLisc[];
    signature: DisplaySignature_DOC | null;
  } = {
    idDocs: [],
    adHocDocs: [],
    drivingLic: [],
    signature: null,
  };
  fileToBeDeletedDriver: {
    fileId: number;
  } | null = null;
  fileToBeDeletedID: {
    fileId: number;
  } | null = null;
  fileToBeDeletedAdhoc: {
    fileId: number;
  } | null = null;
  multiMediaViewer: { open: boolean; file: File | null } = {
    open: false,
    file: null,
  };

  viewMedia(file: File) {
    this.multiMediaViewer.file = file;
    this.multiMediaViewer.open = true;
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }
  get getNewDl() {
    return this.newDl;
  }
  errorHandler(e: any) {
    //   logger.log("Error", e);
  }
  validateForm(val: string) {
    logger.warn("Validate form ");
    const elem = this.$refs[val] as HTMLFormElement;
    if (elem) {
      elem.validate();
    }
  }

  /* async deleteDriver(): Promise<void> {
    try {
      this.loading.drivingLic = true;
      if (this.getAroDocs) {
        const fileId = this.getAroDocs.drivingLicenses[0].id;

        const res = await Documents.deleteDrivingLicense(fileId, 1);
        const resRenew = await Documents.deleteDrivingLicense(fileId, 2);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading.drivingLic = false;
    }
  } */

  deleteDriver() {
    if (this.getAroDocs) {
      const fileId = this.getAroDocs.drivingLicenses[0].id;
      this.fileToBeDeletedDriver = {
        fileId: fileId,
      };
      this.currentDoc.drivingLic = [];
    } else {
      this.$notifCreator.createErrorNotification(
        "Something went wrong while deleting the file. If this problem persists, please contact the system administrator."
      );
    }
  }

  async processDeleteDriver() {
    try {
      this.loading.drivingLic = true;
      if (this.fileToBeDeletedDriver != null) {
        const res = await Documents.deleteDrivingLicense(
          this.fileToBeDeletedDriver.fileId,
          1
        );
        const resRenew = await Documents.deleteDrivingLicense(
          this.fileToBeDeletedDriver.fileId,
          2
        );

        this.fileToBeDeletedDriver = null;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.$emit("refresh");
      this.loading.drivingLic = false;
    }
  }

  /* async deleteID(): Promise<void> {
    try {
      this.loading.id = true;
      if (this.getAroDocs) {
        const fileId = this.getAroDocs.idDocuments[0].id;

        const res = await Documents.deleteIdDocument(fileId);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading.id = false;
    }
  } */

  deleteID() {
    if (this.getAroDocs) {
      const fileId = this.getAroDocs.idDocuments[0].id;
      this.fileToBeDeletedID = {
        fileId: fileId,
      };
      this.currentDoc.idDocs = [];
    } else {
      this.$notifCreator.createErrorNotification(
        "Something went wrong while deleting the file. If this problem persists, please contact the system administrator."
      );
    }
  }

  async processDeleteID() {
    try {
      this.loading.id = true;
      if (this.fileToBeDeletedID != null) {
        const res = await Documents.deleteIdDocument(
          this.fileToBeDeletedID.fileId
        );
        this.fileToBeDeletedID = null;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.$emit("refresh");
      this.loading.id = false;
    }
  }

  async deleteAdhoc(fileId: number): Promise<void> {
    try {
      this.loading.adhoc = true;

      const res = await Documents.deleteAdhocTraining(fileId);

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading.adhoc = false;
    }
  }

  /*  deleteAdhoc(fileId: number) {
    if (this.currentDoc) {
      this.fileToBeDeletedAdhoc = {
        fileId: fileId,
      };
      this.currentDoc.adHocDocs = [];
    } else {
      this.$notifCreator.createErrorNotification(
        "Something went wrong while deleting the file. If this problem persists, please contact the system administrator."
      );
    }
  }

  async processDeleteAdhoc() {
    try {
      this.loading.adhoc = true;
      if (this.fileToBeDeletedAdhoc != null) {
        const res = await Documents.deleteAdhocTraining(
          this.fileToBeDeletedAdhoc.fileId
        );
        this.fileToBeDeletedAdhoc = null;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.$emit("refresh");
      this.loading.adhoc = false;
    }
  } */

  async addDrivingLic(): Promise<void> {
    try {
      this.loading.drivingLic = true;
      if (this.validDL == true && this.getAroDocs && this.newDl) {
        const formData = new FormData();
        formData.append("DrivingLicenseAROID", this.getAroDocs.id.toString());
        formData.append("DrivingLicenseID", String(0));

        if (this.newDl.file != null) formData.append("Image", this.newDl.file);

        formData.append(
          "DrivingLicenseExpiryDate",
          String(this.newDl.expiryDate)
        );
        formData.append("IsRenewed", String(this.newDl.isRenewed));

        if (this.newDl.renewedFile)
          formData.append(
            "RenewedDocumentFilePath",
            this.newDl.renewedFile != null && this.newDl.isRenewed == true
              ? this.newDl.renewedFile
              : ""
          );

        formData.append(
          "RenewedDate",
          this.newDl.renewedDate != null && this.newDl.isRenewed == true
            ? this.newDl.renewedDate
            : ""
        );

        formData.append("UploadDocument", this.newDl.UploadDocument.toString());
        formData.append("DeviceId", this.newDl.DeviceId);

        await this.processDeleteDriver();
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addDrivingLicense",
          formData
        );
        // logger.log("Add DL response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.drivingLic = false;
    }
  }
  async updateDrivingLic(): Promise<void> {
    try {
      this.loading.drivingLic = true;
      const elem = this.getCurrentDoc.drivingLic;
      if (elem.length > 0) {
        const formData = new FormData();
        formData.append(
          "DrivingLicenseAROID",
          elem[0].data.armResponseOfficerID.toString()
        );
        formData.append("DrivingLicenseID", elem[0].data.id.toString());
        if (elem[0].preview.file != null)
          formData.append("Image", elem[0].preview.file);
        formData.append(
          "DrivingLicenseExpiryDate",
          String(elem[0].preview.date)
        );
        formData.append("IsRenewed", String(elem[0].isRenewed));
        if (elem[0].previewRenewed.file != null)
          formData.append(
            "RenewedDocumentFilePath",
            elem[0].previewRenewed.file != null && elem[0].isRenewed == true
              ? elem[0].previewRenewed.file
              : ""
          );

        formData.append(
          "RenewedDate",
          elem[0].isRenewed == true ? String(elem[0].previewRenewed.date) : ""
        );

        formData.append(
          "UploadDocument",
          elem[0].data.UploadDocument.toString()
        );
        formData.append("DeviceId", elem[0].data.DeviceId);

        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addDrivingLicense",
          formData
        );
        // logger.log("Update DL response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.drivingLic = false;
    }
  }
  get getCurrentDoc() {
    return this.currentDoc;
  }
  insertedAdhoc(arr: File[]): void {
    //   logger.log("Testing inserting files trigger ", arr);
    // if (arr.length > 0) {
    //   arr.forEach((f) => {
    //     const found = this.statements.findIndex((e) => e.file?.name == f.name);
    //     if (found == -1) {
    //       this.statements.push({
    //         file: f,
    //         id: Math.random() * 100,
    //       });
    //     }
    //   });
    //   arr.splice(0);
    // } else {
    //   this.statements.splice(0);
    // }
  }
  async initAdhoc(): Promise<void> {
    const temp: Promise<File | null>[] = [];
    // if (this.certificate != null) {
    //   this.certificate.statementsPath.forEach((element: string) => {
    //  //   logger.log("Running for ", element);
    //     const that = Documents.getFile(element);
    //     if (that != null) temp.push(that);
    //   });
    //   const certArr = await Promise.all(temp);
    ////   logger.log("Statements : ", certArr);
    //   this.statements.splice(0);
    //   certArr.forEach((cert) => {
    //     this.statements.push({
    //       file: cert,
    //       id: Math.random() * 100,
    //     });
    //   });
    // } else {
    //   this.statements.splice(0);
    // }

    return Promise.resolve();
  }
  get getDrivingLicenses(): DrivingLicense_DOC[] {
    if (this.getAroDocs != null) {
      return this.getAroDocs.drivingLicenses;
    } else return [];
  }
  get getIdDocs(): ID_DOC[] {
    if (this.getAroDocs != null) {
      return this.getAroDocs.idDocuments;
    } else return [];
  }
  get getSignature(): SignatureViewModel | null {
    if (this.getAroDocs != null) {
      return this.getAroDocs.userSignature;
    } else return null;
  }
  async addAdhoc(): Promise<void> {
    try {
      this.loading.id = true;
      if (this.newAdhoc.file != null && this.getAroDocs != null) {
        const formData = new FormData();
        formData.append("AdhocTrainingDocumentID", String(0));
        formData.append(
          "Description",
          this.newAdhoc.description != null ? this.newAdhoc.description : ""
        );
        formData.append(
          "AdhocFile",
          this.newAdhoc.file != null ? this.newAdhoc.file : ""
        );
        formData.append("ArmResponseOfficerID", this.getAroDocs.id.toString());
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addAdhocTraining",
          formData
        );
        this.newAdhoc = {
          expanded: false,
          file: null,
          src: "",
          description: null,
        };
        // logger.log("Add ID response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.id = false;
    }
  }
  async updateAdhoc(doc: DisplayAdhoc): Promise<void> {
    try {
      this.loading.adhoc = true;
      const elem = this.getCurrentDoc.idDocs;
      if (elem.length > 0 && doc.file != null && this.getAroDocs != null) {
        const formData = new FormData();
        formData.append("AdhocTrainingDocumentID", doc.data.id.toString());
        formData.append(
          "Description",
          doc.description == null ? "" : doc.description
        );
        formData.append("AdhocFile", doc.file);
        formData.append("ArmResponseOfficerID", this.getAroDocs.id.toString());
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addAdhocTraining",
          formData
        );
        // logger.log("Add ID response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.id = false;
    }
  }
  async addIDDoc(): Promise<void> {
    try {
      this.loading.id = true;
      if (this.newID.file != null && this.getAroDocs != null) {
        const formData = new FormData();
        formData.append("IDDocumentAROID", this.getAroDocs.id.toString());
        formData.append("IDDocumentFile", this.newID.file);
        await this.processDeleteID();
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addIdDocument",
          formData
        );
        // logger.log("Add ID response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.id = false;
    }
  }
  async updateIDDoc(): Promise<void> {
    try {
      this.loading.id = true;
      const elem = this.getCurrentDoc.idDocs;
      if (elem.length > 0 && elem[0].preview.file != null) {
        const formData = new FormData();
        formData.append(
          "IDDocumentAROID",
          elem[0].data.armResponseOfficerID.toString()
        );
        formData.append("IDDocumentFile", elem[0].preview.file);
        formData.append("IDDocumentID", elem[0].data.id.toString());
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addIdDocument",
          formData
        );
        // logger.log("Add ID response", res.data);
        this.$emit("refresh");
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.id = false;
    }
  }

  /* getFileSizeFromBase64(b64: string): number {
    var stringLength = b64.length - "data:image/png;base64,".length;
    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInKb = sizeInBytes / 1000;

    return sizeInBytes;
  } */

  async addAROSignature(b64: string): Promise<void> {
    logger.log("Signature b64: ", b64);
    if (b64 != undefined) {
      this.newSignature.file = b64;

      try {
        this.loading.signature = true;
        if (this.newSignature.file != null) {
          const data: AROSignatureViewModel = {
            aroId: this.getAroDocs!.id,
            fileName:
              /* `${this.getAroDocs!.name} ${
            this.getAroDocs!.surname
          } signature.png` */ "",
            fileSize: /* this.getFileSizeFromBase64(this.newSignature.file) */ 0,
            mimeType:
              /*  Documents.detectMimeType(this.newSignature.file)! */ "",
            upload: this.newSignature.file,
          };
          const res = await axiosInstance.post(
            "/api/ManageDocumentsAPI/UpdateORAddAROSignature",
            data
          );

          this.$emit("refresh");
        }
      } catch (err) {
        logger.log("Err adding/ updating signature: ", err);
        return Promise.reject(err);
      } finally {
        this.loading.signature = false;
      }
    }
  }

  async updateAROSignature(b64: string): Promise<void> {
    logger.log("Signature b64: ", b64);
    if (b64 != undefined) {
      try {
        this.loading.signature = true;

        const data: AROSignatureViewModel = {
          aroId: this.getAroDocs!.id,
          fileName:
            /* `${this.getAroDocs!.name} ${
            this.getAroDocs!.surname
          } signature.png` */ "",
          fileSize: /* this.getFileSizeFromBase64(this.newSignature.file) */ 0,
          mimeType: /*  Documents.detectMimeType(this.newSignature.file)! */ "",
          upload: b64,
        };
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/UpdateORAddAROSignature",
          data
        );

        this.$emit("refresh");
      } catch (err) {
        logger.log("Err adding/ updating signature: ", err);
        return Promise.reject(err);
      } finally {
        this.loading.signature = false;
      }
    }
  }
  async initDrivingLicenses() {
    // push empty promise for renewed == null
    // each has 2 => Promise.all result works in index pairs then
    const deviceId = (await Device.getId()).uuid;
    this.newDl = {
      expanded: false,
      file: null,
      fileSrc: "",
      renewedDate: null,
      isRenewed: false,
      isOpenExpiryDate: false,
      isOpenRenewedDate: false,
      renewedSrc: "",
      renewedFile: null,
      expiryDate: "",
      UploadDocument: true,
      DeviceId: deviceId,
    };

    const dls = this.getDrivingLicenses;
    if (dls.length > 0) {
      this.currentDoc.drivingLic.splice(0);
      const promises: Promise<any>[] = [];
      dls.forEach((element) => {
        promises.push(Documents.getFile(element.imagePath));
        if (element.renewedDocumentFilePath != null) {
          promises.push(Documents.getFile(element.renewedDocumentFilePath));
        } else {
          promises.push(Promise.resolve());
        }
      });
      const resIds = await Promise.all(promises);
      dls.forEach((element, index) => {
        // logger.warn("Pushing element ", element.id);
        this.currentDoc.drivingLic.push({
          data: {
            ...element,
            DeviceId: deviceId,
            UploadDocument:
              resIds[index * 2] != null || resIds[index * 2 + 1] != null
                ? true
                : false,
          },
          expanded: false,
          isRenewed: element.isRenewed,
          preview: {
            src:
              resIds[index * 2] != null
                ? URL.createObjectURL(resIds[index * 2])
                : "",
            file: resIds[index * 2],
            date: dayjs(element.expiryDate).format("YYYY-MM-DD"),
            open: false,
          },
          previewRenewed: {
            src:
              resIds[index * 2 + 1] != null
                ? URL.createObjectURL(resIds[index * 2 + 1])
                : "",
            file: resIds[index * 2 + 1],
            date:
              element.renewedDate != null
                ? dayjs(element.renewedDate).format("YYYY-MM-DD")
                : dayjs().format("YYYY-MM-DD"),
            open: false,
          },
        });
      });
    }
    this.fileToBeDeletedDriver = null;
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  get getAdhoc(): ADHOC_DOC[] {
    if (this.getAroDocs != null) return this.getAroDocs.adhocTrainingDocuments;
    else return [];
  }
  async initAdhocDocs(): Promise<void> {
    const docs = this.getAdhoc;
    if (docs.length > 0) {
      logger.warn("Init adhoc docs");
      // init them with new interface
      this.currentDoc.adHocDocs.splice(0);
      const promises: Promise<any>[] = [];
      docs.forEach((element) => {
        promises.push(Documents.getFile(element.filePath));
      });
      const res = await Promise.all(promises);
      docs.forEach((element, index) => {
        // logger.warn("Pushing element ", element.id);
        this.currentDoc.adHocDocs.push({
          expanded: false,
          data: element,
          file: res[index],
          src: res[index] ? URL.createObjectURL(res[index]) : "",
          description: element.description,
        });
      });
    }
    this.fileToBeDeletedAdhoc = null;
  }
  async initIdDocs(): Promise<void> {
    const ids = this.getIdDocs;
    if (ids.length > 0) {
      logger.warn("Init id docs");
      // init them with new interface
      this.currentDoc.idDocs.splice(0);
      const idPromises: Promise<any>[] = [];
      ids.forEach((element) => {
        idPromises.push(Documents.getFile(element.filePath));
      });
      const resIds = await Promise.all(idPromises);
      ids.forEach((element, index) => {
        // logger.warn("Pushing element ", element.id);
        this.currentDoc.idDocs.push({
          data: element,
          preview: {
            src: resIds[index] ? URL.createObjectURL(resIds[index]) : "",
            file: resIds[index],
          },
          expanded: false,
        });
      });
    }
    this.fileToBeDeletedID = null;
  }

  async initSignature(): Promise<void> {
    const signature = this.getSignature;
    if (signature != null) {
      logger.warn("Init Signature");
      // init them with new interface

      const base64 = `data:${signature.mimeType};base64,${signature.dataFiles}`;
      /* FILE CONSTRUCT */
      const filetype = signature.mimeType;
      const int8Array = await fetch(base64)
        .then((res) => res.arrayBuffer())
        .then((buffer) => new Uint8Array(buffer));
      const file = new File(
        [new Blob([int8Array], { type: filetype })],
        signature.fileName,
        {
          type: filetype,
        }
      );

      /* this.currentDoc.signature!.aroId = this.getAroDocs!.id;
      this.currentDoc.signature!.fileName = signature!.fileName;
      this.currentDoc.signature!.fileSize = signature!.fileSize;
      this.currentDoc.signature!.mimeType = signature!.mimeType;
      this.currentDoc.signature!.upload = signature!.dataFiles; */

      this.currentDoc.signature = {
        aroId: this.getAroDocs!.id,
        expanded: false,
        fileName: signature.fileName,
        fileSize: signature.fileSize,
        mimeType: signature.mimeType,
        preview: {
          file: file,
          src: base64,
        },
        upload: signature.dataFiles,
      };
    }
  }
  async init(): Promise<void> {
    // await this.initIdDocs();
    // try {
    ////   logger.log("Init docs in BasicPus");
    //   this.loading = {
    //     id: true,
    //     adhoc: true,
    //     drivingLic: true,
    //   };
    //   if (this.certificate != null) {
    //     this.currentDoc.idDoc =
    //       this.certificate.certificateFilesPath != null
    //         ? await Documents.getFile(this.certificate.certificateFilesPath)
    //         : null;
    //   } else {
    //     this.currentDoc.idDoc = null;
    //     this.currentDoc.adHocDocs = [];
    //   }
    //   const temp = [this.initIds(), this.initAdhoc(), this.initDrivingLic()];
    //   const res = await Promise.all(temp);
    ////   logger.log("Results :", res);
    //   return Promise.resolve();
    // } catch (err) {
    //   logger.error("BasicPus Init error: ", err);
    // } finally {
    //   this.loading = {
    //     id: false,
    //     adhoc: false,
    //     drivingLic: false,
    //   };
    // }
  }
  @Watch("getIdDocs", {
    immediate: true,
  })
  async onIdDocsChanged(val: ID_DOC[]): Promise<void> {
    logger.warn("ID DOCs Watcher", val);
    await this.initIdDocs();
  }
  @Watch("getSignature", {
    immediate: true,
  })
  async onSignatureChanged(val: AROSignatureViewModel): Promise<void> {
    logger.warn("Signature Watcher", val);
    await this.initSignature();
  }
  @Watch("getAdhoc", {
    immediate: true,
  })
  async onAdhocChanged(val: ID_DOC[]): Promise<void> {
    logger.warn("Adhoc Watcher", val);
    await this.initAdhocDocs();
  }
  @Watch("getDrivingLicenses", {
    immediate: true,
  })
  async onDLSChanged(val: ID_DOC[]): Promise<void> {
    logger.warn("Driving licenses Watcher", val);
    await this.initDrivingLicenses();
  }
  async mounted(): Promise<void> {
    await this.init();
  }
}
