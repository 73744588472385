
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import MultimediaViewer from "@/components/MultimediaViewer.vue";

@Component({
  components: {
    VuePdfApp,
    MultimediaViewer,
  },
})
export default class RangeOfficerUnitStandards extends Mixins(FileMixin) {
  @Prop() aroId!: number;
  valid = false;
  loading = false;
  URL = URL;

  currentDoc: {
    onlyRange: {
      file: File | null;
      expanded: boolean;
      sorExpanded: boolean;
      fileSrc: string;
      sorFile: File | null;
      sorSrc: string;
    };
    includingExcercises: {
      expanded: boolean;
      sorExpanded: boolean;
      file: File | null;
      fileSrc: string;
      sorFile: File | null;
      sorSrc: string;
    };
  } = {
    onlyRange: {
      expanded: false,
      sorExpanded: false,
      file: null,
      fileSrc: "",
      sorFile: null,
      sorSrc: "",
    },
    includingExcercises: {
      sorExpanded: false,
      expanded: false,
      file: null,
      fileSrc: "",
      sorFile: null,
      sorSrc: "",
    },
  };
  multiMediaViewer: { open: boolean; file: File | null } = {
    open: false,
    file: null,
  };

  viewMedia(file: File) {
    this.multiMediaViewer.file = file;
    this.multiMediaViewer.open = true;
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  get getAroDocs() {
    return Docs.getAroDocs;
  }

  get getRangeOfficer() {
    if (this.getAroDocs != null)
      return this.getAroDocs.rangeOfficerUnitStandards;
    else return [];
  }

  async deleteFile(file: number): Promise<void> {
    try {
      this.loading = true;
      if (this.getAroDocs && this.getAroDocs.rangeOfficerUnitStandards) {
        const fileId = this.getAroDocs.rangeOfficerUnitStandards[0].id;

        const res = await Documents.deleteRangeOfficer(fileId, file);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading = false;
    }
  }

  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }
  async addRangeOfficer(): Promise<void> {
    try {
      this.loading = true;
      const officer = this.getRangeOfficer;
      if (this.getAroDocs != null) {
        const formData = new FormData();
        formData.append(
          "RangeOfficerID",
          officer[0] ? (officer[0] as any).id : 0
        );
        formData.append("RangeOfficerAROID", this.aroId.toString());
        if (this.currentDoc.onlyRange.file != null)
          formData.append("ShootingRange", this.currentDoc.onlyRange.file);
        if (this.currentDoc.includingExcercises.file != null)
          formData.append(
            "TacticalExercises",
            this.currentDoc.includingExcercises.file
          );
        if (this.currentDoc.includingExcercises.sorFile != null)
          formData.append(
            "ShootingRangeStatementOfResult",
            this.currentDoc.includingExcercises.sorFile
          );
        if (this.currentDoc.onlyRange.sorFile != null)
          formData.append(
            "TacticalExercisesStatementOfResult",
            this.currentDoc.onlyRange.sorFile
          );

        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addRangeOfficer",
          formData
        );
        this.$emit("refresh");
        //   logger.log("Add Knowledge of act response", res.data, formData);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async init(): Promise<void> {
    try {
      this.loading = true;
    } finally {
      this.loading = false;
    }
  }
  @Watch("getRangeOfficer", {
    immediate: true,
  })
  async onKoaChanged(val: any | null, oldVal: any | null): Promise<void> {
    //   logger.log("Koa changed from ", oldVal, " to ", val);
    await this.init();
  }
}
