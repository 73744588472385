
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  AroDocs,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import logger from "@/plugins/logger";
import MultimediaViewer from "@/components/MultimediaViewer.vue";
import { Device } from "@capacitor/device";

interface Certificate {
  armResponseOfficerID: number;
  certificateFilesPath: string;
  certificates: string[];
  certificatesPath: string[] | null;
  createdBy: string;
  createdDate: string;
  firearmType: string;
  id: number;
  isTacticalUnitStandards: boolean;
  statementOfResultFilesPath: string;
  statements: string[];
  statementsPath: string[];
  updatedBy: string;
  updatedDate: string;
  uploadDocument: boolean;
}

@Component({
  components: {
    VuePdfApp,
    MultimediaViewer,
  },
})
export default class BusinessPus extends Mixins(FileMixin) {
  @Prop() certificate!: Certificate | null;
  @Prop() aroId!: number;
  loading = false;
  currentDoc = {
    BusinessHandgun: false,
    BusinessShotgun: false,
    BusinessSelfloadingrifle: false,
    BusinessManuallyoperatedrifle: false,
    UploadDocument: true,
    DeviceId: "",
  };
  fileToBeDeleted: {
    fileId: number;
    certPath: string;
    statePath: string;
  } | null = null;
  certExpanded = false;
  statementExpanded = false;
  URL = URL;
  newFiles: File[] = [];
  newStatements: File[] = [];
  multiMediaViewer: { open: boolean; file: File | null } = {
    open: false,
    file: null,
  };
  certificates: {
    file: File | null;
    filePath: string;
    preview: string;
    expanded: boolean;
    id: number;
  }[] = [];
  statements: {
    file: File | null;
    filePath: string;
    preview: string;
    expanded: boolean;
    id: number;
  }[] = [];
  get getAroDocs(): AroDocs | null {
    return Docs.getAroDocs;
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  get getSAPSHandgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates
          .filter((e) => e.isRegulation21Certificate == false)
          .find((e) => e.firearmType.includes("Handgun")) != null
      );
    } else return false;
  }

  get getSAPSShotgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates
          .filter((e) => e.isRegulation21Certificate == false)
          .find((e) => e.firearmType.includes("Shotgun")) != null
      );
    } else return false;
  }
  get getSAPSMLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates
          .filter((e) => e.isRegulation21Certificate == false)
          .find((e) => e.firearmType.includes("Manually-Operated Rifle")) !=
        null
      );
    } else return false;
  }
  get getSAPSSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.sapsCompetencyCertificates
          .filter((e) => e.isRegulation21Certificate == false)
          .find((e) => e.firearmType.includes("Self-Loading Rifle")) != null
      );
    } else return false;
  }
  get getBusHandgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.find(
          (e) => e != this.certificate && e.firearmType.includes("Handgun")
        ) != null
      );
    } else return false;
  }

  get getBusShotgun(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.find(
          (e) => e != this.certificate && e.firearmType.includes("Shotgun")
        ) != null
      );
    } else return false;
  }
  get getBusMLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.find(
          (e) =>
            e != this.certificate &&
            e.firearmType.includes("Manually-Operated Rifle")
        ) != null
      );
    } else return false;
  }
  get getBusSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.find(
          (e) =>
            e != this.certificate &&
            e.firearmType.includes("Self-Loading Rifle")
        ) != null
      );
    } else return false;
  }

  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }

  viewMedia(file: File) {
    this.multiMediaViewer.file = file;
    this.multiMediaViewer.open = true;
  }

  deleteFile(certPath: string, statePath: string, index: number) {
    if (this.certificate) {
      const fileId = this.certificate.id;
      this.fileToBeDeleted = {
        fileId: fileId,
        certPath: certPath,
        statePath: statePath,
      };

      if (certPath.length >= 1) {
        this.certificates.splice(index, 1);
      }

      if (statePath.length >= 1) {
        this.statements.splice(index, 1);
      }
    }
  }

  async processDelete() {
    try {
      this.loading = true;
      if (this.fileToBeDeleted != null) {
        const res = await Documents.deleteBusinessProficiency(
          this.fileToBeDeleted.fileId,
          this.fileToBeDeleted.statePath,
          this.fileToBeDeleted.certPath
        );

        this.fileToBeDeleted = null;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.$emit("refresh");
      this.loading = false;
    }
  }
  insertedStatements(arr: File[]): void {
    //   logger.log("Testing inserting files trigger ", arr);
    if (arr.length > 0) {
      arr.forEach((f) => {
        const found = this.statements.findIndex((e) => e.file?.name == f.name);
        if (found == -1) {
          if (f) {
            this.statements.push({
              preview: URL.createObjectURL(f),
              expanded: false,
              file: f,
              id: Math.random() * 100,
              filePath: "",
            });
          }
        }
      });

      arr.splice(0);
    } else {
      this.statements.splice(0);
    }
  }
  insertedFiles(arr: File[]): void {
    //   logger.log("Testing inserting files trigger ", arr);
    if (arr.length > 0) {
      arr.forEach((f) => {
        const found = this.certificates.findIndex(
          (e) => e.file?.name == f.name
        );
        if (found == -1) {
          this.certificates.push({
            preview: URL.createObjectURL(f),
            expanded: false,
            file: f,
            id: Math.random() * 100,
            filePath: "",
          });
        }
      });
      arr.splice(0);
    } else {
      this.certificates.splice(0);
    }
  }
  async initCerts(): Promise<void> {
    const temp: Promise<File | null>[] = [];
    //   logger.log("Init Certs ------------:");
    if (this.certificate != null && this.certificate.certificatesPath) {
      this.certificate.certificatesPath.forEach((element: string) => {
        //   logger.log("Init a cert :", element);
        const that = Documents.getFile(element);
        if (that != null) temp.push(that);
      });
      const certArr = await Promise.all(temp);
      //   logger.log("Certs : ", certArr);
      const zip = (a: string[], b: (File | null)[]) =>
        a.map((v: string, i: number) => {
          return {
            file: b[i],
            path: v,
          };
        });
      const data = zip(this.certificate.certificatesPath, certArr);
      logger.log("data", data);
      this.certificates.splice(0);
      data.forEach((cert) => {
        if (cert) {
          this.certificates.push({
            preview: URL.createObjectURL(cert.file),
            expanded: false,
            file: cert.file,
            id: Math.random() * 100,
            filePath: cert.path,
          });
        }
      });
    } else {
      this.certificates.splice(0);
    }

    return Promise.resolve();
  }
  async initStatements(): Promise<void> {
    const temp: Promise<File | null>[] = [];
    if (this.certificate != null) {
      this.certificate.statementsPath.forEach((element: string) => {
        //   logger.log("Running for ", element);
        const that = Documents.getFile(element);
        if (that != null) temp.push(that);
      });
      const certArr = await Promise.all(temp);
      //   logger.log("Statements : ", certArr);

      const zip = (a: string[], b: (File | null)[]) =>
        a.map((v: string, i: number) => {
          return {
            file: b[i],
            path: v,
          };
        });
      const data = zip(this.certificate.statementsPath, certArr);
      logger.log("data", data);
      this.statements.splice(0);
      data.forEach((cert) => {
        if (cert && typeof cert !== "string") {
          this.statements.push({
            preview: URL.createObjectURL(cert.file),
            expanded: false,
            file: cert.file,
            id: Math.random() * 100,
            filePath: cert.path,
          });
        }
      });
    }

    return Promise.resolve();
  }
  async init(): Promise<void> {
    try {
      this.loading = true;
      const deviceId = (await Device.getId()).uuid;
      if (this.certificate != null) {
        this.currentDoc.BusinessHandgun =
          this.certificate.firearmType.includes("Handgun");
        this.currentDoc.BusinessShotgun =
          this.certificate.firearmType.includes("Shotgun");
        this.currentDoc.BusinessSelfloadingrifle =
          this.certificate.firearmType.includes("Self-Loading Rifle")
            ? true
            : false;
        this.currentDoc.BusinessManuallyoperatedrifle =
          this.certificate.firearmType.includes("Manually-Operated Rifle")
            ? true
            : false;
        const temp = [this.initCerts(), this.initStatements()];
        const res = await Promise.all(temp);
        this.currentDoc.UploadDocument =
          this.certificate.uploadDocument != null
            ? this.certificate.uploadDocument
            : this.certificates.length > 0 || this.statements.length > 0
            ? true
            : false;
        this.currentDoc.DeviceId = deviceId;
        //   logger.log("Results :", res);
      } else {
        this.currentDoc.BusinessHandgun = false;
        this.currentDoc.BusinessShotgun = false;
        this.currentDoc.BusinessSelfloadingrifle = false;
        this.currentDoc.BusinessManuallyoperatedrifle = false;
        const temp = [this.initCerts(), this.initStatements()];
        const res = await Promise.all(temp);
        this.currentDoc.DeviceId = deviceId;
        this.currentDoc.UploadDocument = true;
        //   logger.log("Results :", res);
      }

      this.fileToBeDeleted = null;
      //   logger.log("Init docs in BasicPus");

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  @Watch("certificate", {
    immediate: true,
  })
  async onCertificateChanged(val: Certificate): Promise<void> {
    //   logger.log("Certificate changed ! ---", val);
    await this.init();
  }
  async mounted(): Promise<void> {
    await this.init();
  }
  async addBPUS(): Promise<void> {
    try {
      this.loading = true;

      const formData = new FormData();
      this.certificates.forEach((element, index) => {
        if (element.file != null) {
          formData.append("BusinessCertificateFiles", element.file);
          // tempCert.push(element.file);
        }
      });
      this.statements.forEach((element) => {
        if (element.file != null) {
          formData.append("BusinessStatementOfResultFiles", element.file);
          // tempState.push(element.file);
        }
      });
      formData.append("BusinessProficiencyAROID", String(this.aroId));
      formData.append(
        "BusinessProficiencyCertificateID",
        this.certificate != null ? String(this.certificate.id) : String(0)
      );
      Object.entries(this.currentDoc).forEach(([key, value]) => {
        if (value == null) {
          formData.append(key, "");
        } else if (typeof value === "number" || typeof value === "boolean") {
          formData.append(key, value.toString());
        } else formData.append(key, value);
      });

      //   logger.log("Form data ", formData);
      await this.processDelete();
      const res = await axiosInstance.post(
        "/api/ManageDocumentsAPI/addBusinessProficiency",
        formData
      );
      this.$emit("refresh");
      //   logger.log("Add Business response", res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  // @Watch("knowledgeOfAct", {
  //   immediate: true,
  // })
  // onKoaChanged(val: string | File | null, oldVal: string | File | null): void {
  ////   logger.log("Koa changed from ", oldVal, " to ", val);
  //   this.koaFile = val;
  // }
  // @Watch("statementOfResults", {
  //   immediate: true,
  // })
  // onSorChanged(val: string | File | null, oldVal: string | File | null): void {
  ////   logger.log("Sor changed from ", oldVal, " to ", val);
  //   this.sorFile = val;
  // }
}
