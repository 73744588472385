
import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  Firearms,
  Documents,
  CRUDFunctionality,
  Permits,
  Downloader,
  Incidents,
} from "@/hooks";
import { Firearm, EditFirearmViewModel, OldPermit } from "models";
import manageItemModal from "@/views/registers/firearms/FirearmsAddEdit.vue";
import dayjs from "dayjs";
import { DataTableHeader } from "vuetify";
import PermitExpansionPanel from "@/partials/PermitExpansionPanel.vue";
import IncidentModal from "@/views/manage/incidents/IncidentsModal.vue";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
    manageItemModal,
    PermitExpansionPanel,
    IncidentModal,
  },
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
    convertDate: function (value: string | null) {
      if (value != null) {
        return dayjs(value).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = exp.diff(today, "d");

      return rem;
    },
  },
})
export default class ManageFirearms extends Vue {
  viewPermits: {
    open: boolean;
    firearm: Firearm | null;
    items: OldPermit[];
  } = {
    firearm: null,
    open: false,
    items: [],
  };
  permits: OldPermit[] = [];
  viewPermitHeaders: DataTableHeader[] = [
    {
      text: "Name",
      value: "armResponseOfficer.name",
    },
    {
      text: "Surname",
      value: "armResponseOfficer.surname",
    },
    // {
    //   text: "Make",
    //   value: "firearm.make",
    // },
    // {
    //   text: "Type",
    //   value: "firearm.typeStr",
    // },
    {
      text: "Expiry Date",
      value: "firearm.expiryDateStr",
    },
    {
      text: "From",
      value: "fromDateStr",
    },
    {
      text: "To",
      value: "fromDateStr",
    },
    {
      text: "Status",
      value: "rowStatus",
    },
    { text: "", value: "data-table-expand" },
  ];
  async loadPermitsForFirearm() {
    try {
      if (this.viewPermits.firearm) {
        this.viewPermits.items.splice(0);
        this.viewPermits.items = await Permits.getAllPermits(
          null,
          {
            startDate: this.dateFilters.from.date,
            endDate: this.dateFilters.to.date,
          },
          [],
          [],
          [this.viewPermits.firearm.id]
        );
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  expPermitsReports: any[] = [];

  expPermitsFields = {
    "Permit Number": "Permit Number",
    ARO: "ARO",
    Firearm: "Firearm",
    "From Date": "From Date",
    "To Date": "To Date",
    "Firearm S/N": "Firearm S/N",
    "Ammunition Calibre": "Ammunition Calibre",
    "Ammunition Count": "Ammunition Count",
    "From Time": "From Time",
    "To Time": "To Time",
    Area: "Area",
    "Nature Of Duty": "Nature Of Duty",
    "Number Of Magazines": "Number Of Magazines",
    Remarks: "Remarks",
    Returned: "Returned",
    "Returned By": "Returned By",
    "Returned Date": "Returned Date",
    "Ammunition Return": "Ammunition Return",
    "Show ARO Signature": "Show ARO Signature",
    "Show Responsible Person Signature": "Show Responsible Person Signature",
    "Approved By": "Approved By",
    "Approved Date": "Approved Date",
    "Declined By": "Declined By",
    "Declined Date": "Declined Date",
  };

  populateExportPermitsData(items: any[]) {
    return items.map((elem) => {
      return {
        "Permit Number":
          elem.savedPermitNumber != null ? elem.savedPermitNumber : "",
        ARO:
          (elem.armResponseOfficer.name != null
            ? elem.armResponseOfficer.name
            : "") +
          ", " +
          (elem.armResponseOfficer.surname != null
            ? elem.armResponseOfficer.surname
            : ""),

        Firearm:
          (elem.firearm.make != null ? elem.firearm.make : "") +
          ", " +
          (elem.firearm.model != null ? elem.firearm.model : "") +
          ", " +
          (elem.firearm.type != null ? elem.firearm.type : "") +
          ", " +
          (elem.firearm.expiryDateStr != null
            ? elem.firearm.expiryDateStr
            : ""),
        "From Date": elem.fromDateStr != null ? elem.fromDateStr : "",
        "To Date": elem.toDateStr != null ? elem.toDateStr : "",
        "Firearm S/N": elem.firearmID != null ? elem.firearmID : "",
        "Ammunition Calibre":
          elem.ammunition.calibre != null ? elem.ammunition.calibre : "",
        "Ammunition Count":
          elem.ammunitionCount != null ? elem.ammunitionCount : "",
        "From Time": elem.fromTime != null ? elem.fromTime : "",
        "To Time": elem.toTime != null ? elem.toTime : "",
        Area: elem.shiftSite.name != null ? elem.shiftSite.name : "",
        "Nature Of Duty": elem.natureOfDuty != null ? elem.natureOfDuty : "",
        "Number Of Magazines":
          elem.numberOfMagazines != null ? elem.numberOfMagazines : "",
        Remarks:
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.remarks != null
              ? elem.ammunitionReturn.remarks
              : ""
            : "N/A",
        Returned:
          elem.isReturned != null
            ? elem.isReturned == true
              ? "Yes"
              : "No"
            : "",
        "Returned By":
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.createdBy != null
              ? elem.ammunitionReturn.createdBy
              : ""
            : "",

        "Returned Date":
          elem.ammunitionReturn != null
            ? dayjs(elem.ammunitionReturn.createdDate).format("YYYY-MM-DD")
            : "",
        "Ammunition Return":
          elem.ammunitionReturn != null
            ? "(total - used = returned) " +
              (elem.ammunitionReturn.totalAllocated != null
                ? elem.ammunitionReturn.totalAllocated
                : "") +
              " - " +
              (elem.ammunitionReturn.usedQuantity != null
                ? elem.ammunitionReturn.usedQuantity
                : "") +
              " = " +
              (elem.ammunitionReturn.returnQuantity != null
                ? elem.ammunitionReturn.returnQuantity
                : "")
            : " REQUIRED",

        "Show ARO Signature":
          elem.showAROSignature != null
            ? elem.showAROSignature == true
              ? "Yes"
              : "No"
            : "",
        "Show Responsible Person Signature":
          elem.showResponsiblePersonSignature != null
            ? elem.showResponsiblePersonSignature == true
              ? "Yes"
              : "No"
            : "",
        "Approved By":
          elem.acceptedBy != null
            ? elem.acceptedBy.userName != null
              ? elem.acceptedBy.userName
              : ""
            : "",
        "Approved Date":
          elem.acceptedDate != null
            ? dayjs(elem.acceptedDate).format("YYYY-MM-DD")
            : "",
        "Declined By": elem.declineBy != null ? elem.declineBy : "",
        "Declined Date":
          elem.declinedDate != null
            ? dayjs(elem.declinedDate).format("YYYY-MM-DD")
            : "",
      };
    });
  }
  async generatePermitsPDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      logger.log("PDF Triggered");
      const columns = [
        { header: "Permit #", dataKey: "Permit Number" },
        { header: "ARO", dataKey: "ARO" },
        { header: "Firearm", dataKey: "Firearm" },
        { header: "From Date", dataKey: "From Date" },
        { header: "To Date", dataKey: "To Date" },
        { header: "Firearm S/N", dataKey: "Firearm S/N" },
        { header: "Ammunition Calibre", dataKey: "Ammunition Calibre" },
        { header: "Ammunition Count", dataKey: "Ammunition Count" },
        { header: "From Time", dataKey: "From Time" },
        { header: "To Time", dataKey: "To Time" },
        { header: "Area", dataKey: "Area" },
        { header: "Nature Of Duty", dataKey: "Nature Of Duty" },
        { header: "Number Of Magazines", dataKey: "Number Of Magazines" },
        { header: "Remarks", dataKey: "Remarks" },
        { header: "Returned", dataKey: "Returned" },
        { header: "Returned By", dataKey: "Returned By" },
        { header: "Returned Date", dataKey: "Returned Date" },
        { header: "Ammunition Return", dataKey: "Ammunition Return" },
        { header: "Show ARO Signature", dataKey: "Show ARO Signature" },
        {
          header: "Show Responsible Person Signature",
          dataKey: "Show Responsible Person Signature",
        },
        { header: "Approved By", dataKey: "Approved By" },
        { header: "Approved Date", dataKey: "Approved Date" },
        { header: "Declined By", dataKey: "Declined By" },
        { header: "Declined Date", dataKey: "Declined Date" },
      ];

      logger.log("PDF Columns generated");
      await Downloader.generatePdfAndSave({
        fileName: `Permit (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "Make",
      });

      logger.log("PDF Exported");
    } catch (err) {
      //comment

      logger.log("PDF Err: ", err);
    }
  }
  //  this.getExpPermitsData = this.populateExportPermitsData(
  //       this.viewPermits.items
  //     );
  get getExpPermitsData() {
    return this.populateExportPermitsData(this.getFilteredPermits);
  }
  get getFilteredPermits(): OldPermit[] {
    let items = this.viewPermits.items;

    return items;
  }

  @Watch("viewPermits.open")
  async onViewPermitOpen(val: boolean, oldVal: boolean) {
    if (val == true) {
      await this.loadPermitsForFirearm();
    }
  }
  dateFilters = {
    to: {
      active: false,
      date: dayjs().format("YYYY-MM-DD"),
    },
    from: {
      active: false,
      date: dayjs().subtract(4, "days").format("YYYY-MM-DD"),
    },
  };
  items: Firearm[] = [];
  itemsFiltered: Firearm[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "expiryDate";
  expanded = [];
  expandedPermits = [];
  deleteModal = false;
  deleteReason = "";
  deleteOtherReason = "";
  importModal = false;
  importFieldShow = false;
  importFile = "";
  importValid = false;
  manageItem = false;
  archiveModal = false;
  archiveReason = "";
  archiveSAP13Number = "";
  archiveCASNumber = "";
  archiveNewOwnerName = "";
  manageItemInfo = "";
  validDelete = false;
  validArchive = false;
  manageItemId = 0;
  refresh = false;
  reportsToUser = [
    {
      name: "",
      value: 0,
    },
  ];
  modalData = {
    type: " ",
    field: {},
  };
  incidentsModal: {
    open: boolean;
    selectedItem: Firearm | null;
    type: "Ammo" | "Firearm" | "ARO";
  } = {
    open: false,
    selectedItem: null,
    type: "Firearm",
  };
  archiveReasons = [
    { name: "License Expired", value: 1 },
    { name: "Voluntarily Forfeited to SAPS", value: 2 },
    { name: "Taken By SAPS", value: 3 },
    { name: "Lost/Stolen", value: 4 },
    { name: "Sold", value: 5 },
    { name: "UN-Serviceable", value: 6 },
  ];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };
  headers: DataTableHeader[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Make", value: "make" },
    { text: "Type", value: "typeStr" },
    { text: "S/N", value: "serialNumber" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days To Expiry", value: "daysToExpiryCalc" },
    { text: "Currently Booked By", value: "bookedBy" }, // TODO: DEPRECATED
    { text: "No of Magazines", value: "numberOfMagazines" },
    { text: "Last Maintenance Date", value: "lastMaintenanceDate" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    Make: string;
    Type: string;
    "S/N": string;
    "Expiry Date": string;
    "Days To Expiry": string;
    "Currently Booked By": string;
    "No of Magazines": string;
    "Last Maintenance Date": string;
    Branch: string;
    Model: any;
    Picture: string;
    License: string;
    Calibre: string;
    "License Number": string;
    "License Type": string;
    "Date of Issue": string;
    "Is Renewed": string;
    "Renewed Date": string;
    "Renewed File": string;
  }[] = [];
  expFields = {
    Make: "make",
    Type: "typeStr",
    "S/N": "serialNumber",
    "Expiry Date": "expiryDateStr",
    "Days To Expiry": "daysToExpiryCalc",
    "Currently Booked By": "bookedBy", // TODO: DEPRECATED
    "No of Magazines": "numberOfMagazines",
    "Last Maintenance Date": "lastMaintenanceDate",
    Branch: "branch",
    Model: "model",
    Picture: " ",
    License: " ",
    Calibre: "calibre",
    "License Number": "licenseNumber",
    "License Type": "licenseType",
    "Date of Issue": "fromDateStr",
    "Is Renewed": "isRenewed",
    "Renewed Date": "renewedDate",
    "Renewed File": " ",
  };
  async importFirearm(): Promise<void> {
    try {
      logger.log("File:", this.importFile);

      var formdata = new FormData();
      formdata.append("file", this.importFile);

      const res = await Firearms.importFirearm(formdata);
      this.$notifCreator.createSuccessNotification(
        "Firearm(s) Imported Successfully!"
      );
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.importFieldShow = false;
      this.importFile = "";
      this.importModal = false;
      this.loadItems();
    }
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //logger.log("Name: ", name, has);
    return has;
  }
  openViewPermits(item: Firearm) {
    this.viewPermits.firearm = item;
    this.viewPermits.open = true;
    this.viewPermits.items = [];
  }

  @Watch("refresh", { immediate: true })
  modalDataChanged(): void {
    if (this.refresh == true) {
      this.loadItems();
    }
  }

  openEditItem(i: Firearm): void {
    this.modalData.type = "edit";

    let data: EditFirearmViewModel = {
      archiveCAS: i.archiveCAS,
      archiveNewOwner: i.archiveNewOwner,
      archiveReason: i.archiveReason,
      archiveSAP13: i.archiveSAP13,
      branch: i.branch,
      calibre: i.calibre,
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      expiryDate: i.expiryDate,
      fromDate: i.fromDate,
      id: i.id,
      imagePath: i.imagePath,
      isArchived: i.isArchived,
      isBookedOut: i.isBookedOut, // TODO: DEPRECATE
      isDeleted: i.isDeleted,
      isRenewed: i.isRenewed,
      lastMaintenanceDate: i.lastMaintenanceDate,
      licenseNumber: i.licenseNumber,
      licensePath: i.licensePath,
      licenseType: i.licenseType,
      make: i.make,
      model: i.model,
      numberOfMagazines: i.numberOfMagazines,
      reason: i.reason,
      renewedDate: i.renewedDate,
      securityFirmID: i.securityFirmID,
      serialNumber: i.serialNumber,
      type: i.type,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
      licenseFile: "",
      imageFile: "",
      renewedDocumentFilePath: "",
    };

    this.modalData.field = data;
    logger.log(i);
    this.refresh = false;
    this.manageItem = true;
  }

  openAddItem(): void {
    this.modalData.type = "add";
    this.modalData.field = {};
    this.refresh = false;
    this.manageItem = true;
  }

  openDeleteItem(i: Firearm): void {
    this.manageItemInfo = i.make + ", " + i.typeStr + ", " + i.serialNumber;
    this.manageItemId = i.id;
    this.deleteModal = true;
  }

  openArchiveItem(i: Firearm): void {
    this.manageItemInfo = i.make + ", " + i.typeStr + ", " + i.serialNumber;
    this.manageItemId = i.id;
    this.archiveModal = true;
  }

  async archiveItem(): Promise<void> {
    try {
      const res = await Firearms.archiveFirearm(
        this.manageItemId,
        this.archiveReason,
        this.archiveSAP13Number,
        this.archiveCASNumber,
        this.archiveNewOwnerName
      );
      this.$notifCreator.createSuccessNotification(
        "Firearm Archived Succesfully."
      );
      this.loadItems();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.archiveModal = false;
    }
  }

  async deleteItem(): Promise<void> {
    try {
      const res = await Firearms.deleteFirearm(
        this.manageItemId,
        this.deleteReason,
        this.deleteOtherReason
      );
      this.$notifCreator.createSuccessNotification(
        "Firearm Deleted Successfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.loadItems();
    }
  }

  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Make", dataKey: "Make" },
        { header: "Type", dataKey: "Type" },
        { header: "S/N", dataKey: "S/N" },
        { header: "Expiry Date", dataKey: "Expiry Date" },
        { header: "Days To Expiry", dataKey: "Days To Expiry" },
        { header: "Currently Booked By", dataKey: "Currently Booked By" },
        { header: "No of Magazine", dataKey: "No of Magazine" },
        { header: "Last Maintenance Date", dataKey: "Last Maintenance Date" },
        { header: "Branch", dataKey: "Branch" },
        { header: "Model", dataKey: "Model" },
        { header: "Picture", dataKey: "Picture" },
        { header: "License", dataKey: "License" },
        { header: "Calibre", dataKey: "Calibre" },
        { header: "License Number", dataKey: "License Number" },
        { header: "License Type", dataKey: "License Type" },
        { header: "Date of Issue", dataKey: "Date of Issue" },
        { header: "Is Renewed", dataKey: "Is Renewed" },
        { header: "Renewed Date", dataKey: "Renewed Date" },
        { header: "Renewed File", dataKey: "Renewed File" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Firearms (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "Make",
      });
    } catch (err) {
      this.loadItems();
    }
  }

  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const res = await Firearms.getAllFirearms(true);
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    this.expReports = this.populateExportData(this.items);
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  populateExportData(items: Firearm[]) {
    return items.map((elem: Firearm) => {
      return {
        Make: elem.make != null ? elem.make : " N/A",
        Type: elem.typeStr != null ? elem.typeStr : " N/A",
        "S/N": elem.serialNumber != null ? elem.serialNumber : " N/A",
        "Expiry Date": elem.expiryDateStr != null ? elem.expiryDateStr : " N/A",
        "Days To Expiry":
          elem.expiryDate != null ? this.getDaysToExpiry(elem.expiryDate) : " ",
        "Currently Booked By": elem.bookedBy != null ? elem.bookedBy : " N/A", // TODO: DEPRECATED
        "No of Magazines":
          elem.numberOfMagazines != null ? elem.numberOfMagazines : "N/A",
        "Last Maintenance Date":
          elem.lastMaintenanceDate != null
            ? dayjs(elem.lastMaintenanceDate).format("YYYY-MM-DD")
            : " N/A",
        Branch: elem.branch != null ? elem.branch : " N/A",
        Model: elem.model != null ? elem.model : " N/A",
        Picture: " ",
        License: " ",
        Calibre: elem.calibre != null ? elem.calibre : " N/A",
        "License Number":
          elem.licenseNumber != null ? elem.licenseNumber : " N/A",
        "License Type":
          elem.licenseType != null ? elem.licenseType.toString() : " N/A",
        "Date of Issue": elem.fromDateStr != null ? elem.fromDateStr : " N/A",
        "Is Renewed":
          elem.isRenewed != null ? elem.isRenewed.toString() : " N/A",
        "Renewed Date":
          elem.renewedDate != null
            ? dayjs(elem.renewedDate).format("YYYY-MM-DD")
            : " N/A",
        "Renewed File": " ",
      };
    });
  }

  getDaysToExpiry(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");
    return String(rem);
  }

  async downloadwPath(i: any): Promise<void> {
    /* window.open(process.env.VUE_APP_API_BASE + "/" + i); */
    var ext = i.substr(i.lastIndexOf(".") + 1);
    var type = "image/jpeg";
    if (ext == "jpeg" || ext == "jpg") {
      type = "image/jpeg";
    } else if (ext == "pdf") {
      type = "application/pdf";
    } else if (ext == "png") {
      type = "image/png";
    }
    try {
      await Documents.getFile(i).then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: type,
          })
        );
        window.open(url);
        logger.log(response);
      });
    } catch (err) {
      const req = await require("@/assets/FileNotFound.png");
      const resO = await fetch(req);
      const img = await resO.blob();
      const url = URL.createObjectURL(img);
      window.open(url);
      this.$notifCreator.createErrorNotification("Can't find Image.");
      return Promise.reject(err);
    }
  }

  async download(i: any, type: string): Promise<void> {
    if (i.mimeType == null) {
      this.$notifCreator.createErrorNotification("Can't find Image.");
    } else {
      try {
        if (type == "file") {
          await Firearms.DownLoadFirearmFile(i.id).then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            logger.log(response);
          });
        } else if (type == "image") {
          await Firearms.DownLoadFirearmImage(i.id).then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            logger.log(response.type);
          });
        } else if (type == "renewed") {
          await Firearms.DownLoadFirearmRenewedFile(i.id).then(
            (response: any) => {
              const url = window.URL.createObjectURL(
                new Blob([response], {
                  type: response.type,
                })
              );
              window.open(url);
              logger.log(response.type);
            }
          );
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  getColorLMD(i: any): string {
    const condition = dayjs().add(-60, "day");
    const exp = dayjs(i);

    if (exp >= condition) return "green";
    else return "red";
  }

  getColor(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");

    if (rem >= 211) return "green";
    else if (rem >= 181 && rem <= 210) return "yellow";
    else if (rem >= 151 && rem <= 180) return "orange";
    else if (rem >= 0 && rem <= 150) return "red";
    else return "red darken-4";
  }

  openIncidentsForFirearm(item: Firearm) {
    this.incidentsModal.selectedItem = item;
    this.incidentsModal.open = true;
  }
}
