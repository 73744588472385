
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import MultimediaViewer from "@/components/MultimediaViewer.vue";
import { Device } from "@capacitor/device";

interface KOA {
  armResponseOfficerID: number;
  createdBy: string;
  createdDate: string;
  id: number;
  isRenewed: boolean;
  knowledgeOfActFileName: string;
  knowledgeOfActFilePath: string;
  renewedDate: string | null;
  renewedDocumentFilePath: null | string;
  statementOfResultFileName: string;
  statementOfResultFilePath: string;
  updatedBy: string;
  updatedDate: string;
}

@Component({
  components: {
    VuePdfApp,
    MultimediaViewer,
  },
})
export default class KnowledgeOfAct extends Mixins(FileMixin) {
  valid = false;
  loading = false;
  koaExpanded = false;
  sorExpanded = false;
  URL = URL;
  multiMediaViewer: { open: boolean; file: File | null } = {
    open: false,
    file: null,
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  get getAroDocs() {
    return Docs.getAroDocs;
  }

  get getKoa() {
    if (this.getAroDocs != null)
      return this.getAroDocs.basicFirearmProficiencies;
    else return [];
  }

  koaFile: string | File | null = null;
  sorFile: string | File | null = null;
  currentDoc = { UploadDocument: true };
  koaFileToBeDeleted: { fileId: number; fileOption: number } | null = null;
  sorFileToBeDeleted: { fileId: number; fileOption: number } | null = null;

  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }

  viewMedia(file: File) {
    this.multiMediaViewer.file = file;
    this.multiMediaViewer.open = true;
  }

  deleteFile(file: number) {
    if (this.getAroDocs) {
      const fileId = this.getAroDocs.basicFirearmProficiencies[0].id;

      if (file == 1) {
        this.koaFileToBeDeleted = {
          fileId: fileId,
          fileOption: file,
        };
        this.koaFile = null;
      }

      if (file == 2) {
        this.sorFileToBeDeleted = {
          fileId: fileId,
          fileOption: file,
        };
        this.sorFile = null;
      }
    } else {
      this.$notifCreator.createErrorNotification(
        "Something went wrong while deleting the file. If this problem persists, please contact the system administrator."
      );
    }
  }

  async processDelete() {
    try {
      this.loading = true;
      if (this.koaFileToBeDeleted != null) {
        const res = await Documents.deleteBasicFirearmProficiency(
          this.koaFileToBeDeleted.fileId,
          this.koaFileToBeDeleted.fileOption
        );

        this.koaFileToBeDeleted = null;
      }

      if (this.sorFileToBeDeleted != null) {
        const res = await Documents.deleteBasicFirearmProficiency(
          this.sorFileToBeDeleted.fileId,
          this.sorFileToBeDeleted.fileOption
        );

        this.sorFileToBeDeleted = null;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.$emit("refresh");
      this.loading = false;
    }
  }

  get getKoaPreview() {
    // return this.psiraPreview;
    if (this.koaFile != null && typeof this.koaFile !== "string") {
      return URL.createObjectURL(this.koaFile);
    } else return "";
  }
  get getSorPreview() {
    // return this.psiraPreview;
    if (this.sorFile != null && typeof this.sorFile !== "string")
      return URL.createObjectURL(this.sorFile);
    else return "";
  }
  async addKOA(): Promise<void> {
    try {
      this.loading = true;
      if (this.getAroDocs != null) {
        const deviceId = (await Device.getId()).uuid;
        const formData = new FormData();
        if (this.koaFile != null)
          formData.append("KnowledgeFile", this.koaFile);

        if (this.sorFile != null) formData.append("ResultFile", this.sorFile);

        formData.append(
          "BasicFirearmProficiencyID",
          this.getKoa[0] ? this.getKoa[0].id.toString() : String(0)
        );

        formData.append("BasicFirearmAROID", this.getAroDocs.id.toString());

        formData.append(
          "UploadDocument",
          this.currentDoc.UploadDocument.toString()
        );

        formData.append("DeviceId", deviceId);

        //formData.forEach((e, i) => {
        //   logger.log("Items ", e, i);
        //});

        await this.processDelete();
        const res = await axiosInstance.post(
          "/api/ManageDocumentsAPI/addBasicFirearmProficiency",
          formData
        );
        this.$emit("refresh");
        //   logger.log("Add Knowledge of act response", res.data, formData);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async init(): Promise<void> {
    try {
      this.loading = true;
      if (this.getKoa[0]) {
        const res = await Promise.all([
          Documents.getFile(this.getKoa[0].knowledgeOfActFilePath),
          Documents.getFile(this.getKoa[0].statementOfResultFilePath),
        ]);
        this.koaFile = res[0];
        this.sorFile = res[1];
        this.currentDoc.UploadDocument =
          this.getKoa[0].uploadDocument != null
            ? this.getKoa[0].uploadDocument
            : this.koaFile != null || this.sorFile != null
            ? true
            : false;
      }
      this.koaFileToBeDeleted = null;
      this.sorFileToBeDeleted = null;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  @Watch("getKoa", {
    immediate: true,
  })
  async onKoaChanged(val: KOA | null, oldVal: KOA | null): Promise<void> {
    //   logger.log("Koa changed from ", oldVal, " to ", val);
    await this.init();
  }
}
